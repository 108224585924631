import { useState, useEffect, useLayoutEffect, useCallback, useRef, ChangeEvent } from "react";
import {
  Toolbar,
  SaveButton,
  Create,
  Edit,
  EditButton,
  Datagrid,
  SimpleForm,
  TextField,
  TextInput,
  DeleteWithConfirmButton,
  useDataProvider,
  useNotify,
  useGetOne,
  useRecordContext,
  ImageInput,
  ImageField,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  useRedirect,
  minLength,
  maxLength,
  Validator,
  required,
  ListProps,
  EditProps,
  Button,
  FunctionField,
} from "react-admin";
import { EditTitle, PathField } from "../Common";
import { useMutation } from "react-query";
import { useFormContext } from "react-hook-form";
import { HTMLPreviewRawText, AnnouncementBuilderComponent, AnnouncementBuilderComponentV130 } from "./htmlComponent";
import { AnnouncementTemplateLoader } from "./templateLoader";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField, NumField } from "../Common";
import { DateTimeSecWithButtonInput } from "components/Common/Input";
import { ImageSize } from "./imageSize";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import dataProvider from "providers/dataProvider";
import { Box, IconButton, Button as MuiButton } from "@mui/material";
import { FileDownloadOutlined, Publish } from "@mui/icons-material";
import { useApiEndpoint } from "providers/apiEndpointProvider";
import { FormatSimpleDateToJST } from "utils/date";
import humps from "humps";

const filters = [
  <TextInput key="id" source="Id" label="Id" />,
  <TextInput key="title" source="Title_like" label="Title Like" />,
  <TextInput key="body" source="Body_like" label="Body Like" />,
  <TextInput key="path" source="Path_like" label="Path Like" />,
  <DateTimeWithSecInput key="openAtFrom" source="OpenAt_from" label="OpenAt From" />,
  <DateTimeWithSecInput key="openAtTo" source="OpenAt_to" label="OpenAt To" />,
  <DateTimeWithSecInput key="closeFrom" source="Close_from" label="CloseAt From" />,
  <DateTimeWithSecInput key="closeTo" source="Close_to" label="CloseAt To" />,
  <TextInput key="category" source="Category_like" label="Category Like" />,
  <TextInput key="tag" source="Tag_like" label="Tag Like" />,
  <TextInput key="bannerUrl" source="BannerUrl_like" label="BannerUrl Like" />,
  <TextInput key="thumbnailUrl" source="ThumbnailUrl_like" label="ThumbnailUrl Like" />,
  <NumberInput key="priority" source="Priority" label="Priority" />,
];

export const AnnouncementList = (props: ListProps) => {
  const [showBody, setShowBody] = useState(false);

  const toggleShowBody = () => {
    setShowBody(!showBody);
  };

  return (
    <CommonList {...props} filters={filters} sort={{ field: "Priority", order: "DESC" }} showWildcardHelp={true}>
      <Button onClick={toggleShowBody} startIcon={showBody ? <VisibilityOffIcon /> : <VisibilityIcon />} label={showBody ? "本文非表示" : "本文表示"} />
      <Datagrid bulkActionButtons={false}>
        <TextField source="Id" label="Id" />
        <TextField source="Title" label="Title" />
        {showBody && <TextField source="Body" label="Body" />}
        <PathField source="Path" label="Path" />
        <YMDHMSDateField source="OpenAt" label="OpenAt" />
        <YMDHMSDateField source="CloseAt" label="CloseAt" />
        <TextField source="Category" label="Category" />
        <TextField source="Tag" label="Tag" />
        <ImageField source="BannerUrl" label="Banner" />
        <ImageSize source="BannerUrl" label="BannerSize" />
        <PathField source="ThumbnailUrl" label="ThumbnailURL" />
        <ImageField source="ThumbnailUrl" label="Thumbnail" />
        <ImageSize source="ThumbnailUrl" label="ThumbnailSize" />
        <NumField source="Priority" label="Priority" />
        <EditButton />
        <FunctionField render={(r: any) => <ExportButton announcementId={r.id} />} />
      </Datagrid>
    </CommonList>
  );
};

export const AnnouncementListV130 = (props: ListProps) => {
  const [showBody, setShowBody] = useState(false);

  const toggleShowBody = () => {
    setShowBody(!showBody);
  };

  return (
    <CommonList {...props} filters={filters} sort={{ field: "Priority", order: "DESC" }} showWildcardHelp={true}>
      <Button onClick={toggleShowBody} startIcon={showBody ? <VisibilityOffIcon /> : <VisibilityIcon />} label={showBody ? "本文非表示" : "本文表示"} />
      <Datagrid bulkActionButtons={false}>
        <TextField source="Id" label="Id" />
        <TextField source="Title" label="Title" />
        {showBody && <TextField source="Body" label="Body" />}
        <PathField source="Path" label="Path" />
        <YMDHMSDateField source="OpenAt" label="OpenAt" />
        <YMDHMSDateField source="CloseAt" label="CloseAt" />
        <TextField source="Category" label="Category" />
        <TextField source="Tag" label="Tag" />
        <PathField source="ThumbnailUrl" label="ThumbnailURL" />
        <NumField source="Priority" label="Priority" />
        <EditButton />
        <FunctionField render={(r: any) => <ExportButton announcementId={r.id} />} />
      </Datagrid>
    </CommonList>
  );
};

const readAsBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const result = e.target?.result;
      if (typeof result !== "string") throw TypeError("Reader did not return string.");
      resolve(result.split("base64,")[1]);
    };

    reader.onerror = (e: ProgressEvent<FileReader>) => {
      reject(e.target?.error);
    };

    reader.readAsDataURL(file);
  });

const HTMLCreateToolbar = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const {
    getValues,
    formState: { isValid },
  } = useFormContext();
  const redirect = useRedirect();

  const banner = getValues("Banner");
  const thumbnail = getValues("Thumbnail");
  const images = getValues("Images");

  const { mutate, isLoading } = useMutation(
    ["create"],
    async () => {
      const e = document.getElementById("preview-html") as HTMLIFrameElement;
      const docType = "<!DOCTYPE html>";
      const html = e.contentDocument!.documentElement.outerHTML;

      let img = [];
      if (images && images.length !== 0) {
        img = await Promise.all(
          images.map(async (img: { rawFile: File }) => {
            return {
              data: await readAsBase64(img.rawFile),
              file_name: img.rawFile.name,
            };
          })
        );
      }

      let bannerData = {};

      if (banner && banner.rawFile) {
        bannerData = {
          data: await readAsBase64(banner.rawFile),
          file_name: banner.rawFile.name,
        };
      }

      return dataProvider.create("Announcement", {
        data: {
          id: getValues("Id"),
          title: getValues("Title"),
          body: getValues("Body"),
          html: docType + html,
          open_at: getValues("OpenAt"),
          close_at: getValues("CloseAt"),
          category: getValues("Category"),
          tag: getValues("Tag"),
          banner: bannerData,
          thumbnail: {
            data: await readAsBase64(thumbnail.rawFile),
            file_name: thumbnail.rawFile.name,
          },
          images: img,
          priority: getValues("Priority"),
        },
      });
    },
    {
      onSuccess: (d: any) => {
        notify("success", { type: "success" });
        redirect("/Announcement");
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  return (
    <Toolbar>
      <SaveButton label="作成" onClick={() => mutate()} disabled={isLoading || !thumbnail || !thumbnail.rawFile || !isValid} />
    </Toolbar>
  );
};

const announcementTemplateBucketPath = process.env.REACT_APP_ANNOUNCEMENT_TEMPLATE_BUCKET_URL;

export const AnnouncementCreate = () => {
  const [html, setHTML] = useState("");

  useLayoutEffect(() => {
    (async () => {
      const h = await AnnouncementTemplateLoader.load(`${announcementTemplateBucketPath!}/index.html`);
      const replaced = AnnouncementTemplateLoader.replaceURL(h, announcementTemplateBucketPath!);
      setHTML(replaced);
    })();
  }, []);

  return (
    <Create>
      <SimpleForm mode="onBlur" toolbar={<HTMLCreateToolbar />}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <ImportButton />
        </Box>
        <AnnouncementBuilderComponent />
        <TextInput source="Id" helperText="指定しない場合はランダムな値が設定" />
        <TextInput source="Title" isRequired />
        <TextInput source="Body" isRequired multiline fullWidth />
        <ImageInput source="Thumbnail" accept="image/*" isRequired>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="Banner" accept="image/*" isRequired>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ArrayInput source="Images" label="画像">
          <SimpleFormIterator inline>
            <ImageInput source="" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="Category" isRequired />
        <TextInput source="Tag" isRequired validate={formValidate.tag} />
        <DateTimeWithSecInput source="OpenAt" isRequired />
        <DateTimeSecWithButtonInput source="CloseAt" isRequired buttonText="常時表示" calcSetDateTimeSec={() => new Date("2999-12-31T00:00:00")} />
        <NumberInput source="Priority" isRequired />
        <HTMLPreviewRawText html={html} />
      </SimpleForm>
    </Create>
  );
};

const HTMLCreateToolbarV130 = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const {
    getValues,
    formState: { isValid },
  } = useFormContext();
  const redirect = useRedirect();

  const thumbnail = getValues("Thumbnail");
  const images = getValues("Images");

  const { mutate, isLoading } = useMutation(
    ["create"],
    async () => {
      const e = document.getElementById("preview-html") as HTMLIFrameElement;
      const docType = "<!DOCTYPE html>";
      const html = e.contentDocument!.documentElement.outerHTML;

      let img = [];
      if (images && images.length !== 0) {
        img = await Promise.all(
          images.map(async (img: { rawFile: File }) => {
            return {
              data: await readAsBase64(img.rawFile),
              file_name: img.rawFile.name,
            };
          })
        );
      }

      return dataProvider.create("Announcement", {
        data: {
          id: getValues("Id"),
          title: getValues("Title"),
          body: getValues("Body"),
          html: docType + html,
          open_at: getValues("OpenAt"),
          close_at: getValues("CloseAt"),
          category: getValues("Category"),
          tag: getValues("Tag"),
          banner: getValues("Banner"),
          thumbnail: {
            data: await readAsBase64(thumbnail.rawFile),
            file_name: thumbnail.rawFile.name,
          },
          images: img,
          priority: getValues("Priority"),
        },
      });
    },
    {
      onSuccess: (d: any) => {
        notify("success", { type: "success" });
        redirect("/Announcement");
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  return (
    <Toolbar>
      <SaveButton label="作成" onClick={() => mutate()} disabled={isLoading || !thumbnail || !thumbnail.rawFile || !isValid} />
    </Toolbar>
  );
};

export const AnnouncementCreateV130 = () => {
  const [html, setHTML] = useState("");

  useLayoutEffect(() => {
    (async () => {
      const h = await AnnouncementTemplateLoader.load(`${announcementTemplateBucketPath!}/index.html`);
      const replaced = AnnouncementTemplateLoader.replaceURL(h, announcementTemplateBucketPath!);
      setHTML(replaced);
    })();
  }, []);

  return (
    <Create>
      <SimpleForm mode="onBlur" toolbar={<HTMLCreateToolbarV130 />}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <ImportButton />
        </Box>
        <AnnouncementBuilderComponentV130 />
        <TextInput source="Id" helperText="指定しない場合はランダムな値が設定" />
        <TextInput source="Title" isRequired />
        <TextInput source="Body" isRequired multiline fullWidth />
        <ImageInput source="Thumbnail" accept="image/*" isRequired>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="Banner" accept="image/*" isRequired>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ArrayInput source="Images" label="画像">
          <SimpleFormIterator inline>
            <ImageInput source="" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="Category" isRequired />
        <TextInput source="Tag" isRequired validate={formValidate.tag} />
        <DateTimeWithSecInput source="OpenAt" isRequired />
        <DateTimeWithSecInput source="CloseAt" isRequired />
        <NumberInput source="Priority" isRequired />
        <HTMLPreviewRawText html={html} />
      </SimpleForm>
    </Create>
  );
};

const AnnouncementUpdateToolbar = (props: any) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const { data: previous, isLoading: isGetOneLoading } = useGetOne("Announcement", { id: record.id });
  const {
    getValues,
    formState: { isValid },
  } = useFormContext();
  const banner = getValues("Banner");
  const thumbnail = getValues("Thumbnail");
  const images = getValues("Images");

  useEffect(() => {
    if (isGetOneLoading) return;
    (async () => {
      const h = await AnnouncementTemplateLoader.load(previous.Path);
      props.setHTML(h);
    })();
  }, [isGetOneLoading]);

  const { mutate, isLoading } = useMutation(
    ["update"],
    async () => {
      const e = document.getElementById("preview-html") as HTMLIFrameElement;
      const docType = "<!DOCTYPE html>";
      const html = e.contentDocument!.documentElement.outerHTML;

      let img = [];
      if (images && images.length !== 0) {
        img = await Promise.all(
          images.map(async (img: { rawFile: File }) => {
            return {
              data: await readAsBase64(img.rawFile),
              file_name: img.rawFile.name,
            };
          })
        );
      }

      let bannerData = {};

      if (banner && banner.rawFile) {
        bannerData = {
          data: await readAsBase64(banner.rawFile),
          file_name: banner.rawFile.name,
        };
      }

      let thumbnailData = {};

      if (thumbnail && thumbnail.rawFile) {
        thumbnailData = {
          data: await readAsBase64(thumbnail.rawFile),
          file_name: thumbnail.rawFile.name,
        };
      }

      return dataProvider.update("Announcement", {
        id: record.id,
        previousData: {
          title: previous.title,
          body: previous.body,
          openAt: previous.openAt,
          closeAt: previous.closeAt,
          category: previous.category,
          tag: previous.tag,
          priority: previous.priority,
        },
        data: {
          title: getValues("Title"),
          html: docType + html,
          body: getValues("Body"),
          openAt: getValues("OpenAt"),
          closeAt: getValues("CloseAt"),
          category: getValues("Category"),
          tag: getValues("Tag"),
          banner: bannerData,
          thumbnail: thumbnailData,
          images: img,
          priority: getValues("Priority"),
          isResetReadFlag: getValues("IsResetUserRead"),
        },
      });
    },
    {
      onSuccess: (d: any) => {
        notify("success", { type: "success" });
        redirect("/Announcement");
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  return (
    <Toolbar>
      <SaveButton label="更新" onClick={() => mutate()} disabled={isGetOneLoading || isLoading || !isValid} />
      <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" translateOptions={{ name: record.id }} />
    </Toolbar>
  );
};

export const AnnouncementEdit = (props: EditProps) => {
  const [html, setHTML] = useState("");

  return (
    <Edit {...props} title={<EditTitle name="Announcement" />}>
      <SimpleForm mode="onBlur" toolbar={<AnnouncementUpdateToolbar setHTML={setHTML} />}>
        <AnnouncementBuilderComponent />
        <TextInput source="Title" />
        <TextInput source="Body" multiline fullWidth />
        <ImageInput source="Thumbnail" accept="image/*" isRequired>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="Banner" accept="image/*" isRequired>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ArrayInput source="Images" label="画像">
          <SimpleFormIterator inline>
            <ImageInput source="" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
        <DateTimeWithSecInput source="OpenAt" />
        <DateTimeWithSecInput source="CloseAt" />
        <TextInput source="Category" />
        <TextInput source="Tag" validate={formValidate.tag} />
        <NumberInput source="Priority" />
        <BooleanInput source="IsResetUserRead" defaultValue={false} />
        <HTMLPreviewRawText html={html} />
      </SimpleForm>
    </Edit>
  );
};

const AnnouncementUpdateToolbarV130 = (props: any) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const { data: previous, isLoading: isGetOneLoading } = useGetOne("Announcement", { id: record.id });
  const {
    getValues,
    formState: { isValid },
  } = useFormContext();
  const thumbnail = getValues("Thumbnail");
  const images = getValues("Images");

  useEffect(() => {
    if (isGetOneLoading) return;
    (async () => {
      const h = await AnnouncementTemplateLoader.load(previous.Path);
      props.setHTML(h);
    })();
  }, [isGetOneLoading]);

  const { mutate, isLoading } = useMutation(
    ["update"],
    async () => {
      const e = document.getElementById("preview-html") as HTMLIFrameElement;
      const docType = "<!DOCTYPE html>";
      const html = e.contentDocument!.documentElement.outerHTML;

      let img = [];
      if (images && images.length !== 0) {
        img = await Promise.all(
          images.map(async (img: { rawFile: File }) => {
            return {
              data: await readAsBase64(img.rawFile),
              file_name: img.rawFile.name,
            };
          })
        );
      }

      let thumbnailData = {};

      if (thumbnail && thumbnail.rawFile) {
        thumbnailData = {
          data: await readAsBase64(thumbnail.rawFile),
          file_name: thumbnail.rawFile.name,
        };
      }

      return dataProvider.update("Announcement", {
        id: record.id,
        previousData: {
          title: previous.title,
          body: previous.body,
          openAt: previous.openAt,
          closeAt: previous.closeAt,
          category: previous.category,
          tag: previous.tag,
          priority: previous.priority,
        },
        data: {
          title: getValues("Title"),
          html: docType + html,
          body: getValues("Body"),
          openAt: getValues("OpenAt"),
          closeAt: getValues("CloseAt"),
          category: getValues("Category"),
          tag: getValues("Tag"),
          thumbnail: thumbnailData,
          images: img,
          priority: getValues("Priority"),
        },
      });
    },
    {
      onSuccess: (d: any) => {
        notify("success", { type: "success" });
        redirect("/Announcement");
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  return (
    <Toolbar>
      <SaveButton label="更新" onClick={() => mutate()} disabled={isGetOneLoading || isLoading || !isValid} />
      <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" translateOptions={{ name: record.id }} />
    </Toolbar>
  );
};

export const AnnouncementEditV130 = (props: EditProps) => {
  const [html, setHTML] = useState("");

  return (
    <Edit {...props} title={<EditTitle name="Announcement" />}>
      <SimpleForm mode="onBlur" toolbar={<AnnouncementUpdateToolbarV130 setHTML={setHTML} />}>
        <AnnouncementBuilderComponentV130 />
        <TextInput source="Title" />
        <TextInput source="Body" multiline fullWidth />
        <ImageInput source="Thumbnail" accept="image/*" isRequired>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="Banner" accept="image/*" isRequired>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ArrayInput source="Images" label="画像">
          <SimpleFormIterator inline>
            <ImageInput source="" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
        <DateTimeWithSecInput source="OpenAt" />
        <DateTimeWithSecInput source="CloseAt" />
        <TextInput source="Category" />
        <TextInput source="Tag" validate={formValidate.tag} />
        <NumberInput source="Priority" />
        <HTMLPreviewRawText html={html} />
      </SimpleForm>
    </Edit>
  );
};

const formValidate: { [field: string]: Validator[] } = {
  tag: [required("必須入力項目です"), minLength(1), maxLength(8, "8文字以内で入力してください")],
};

interface AnnouncementExportData {
  id: string;
  title: string;
  category: string;
  tag: string;
  openAt: string;
  closeAt: string;
  body: string;
  banner?: AnnouncementImage;
  thumbnail: AnnouncementImage;
  images: AnnouncementImage[];
}

interface AnnouncementImage {
  data: string; // base64 encoded
  fileName: string;
}

const ExportButton = (props: { announcementId: string }) => {
  const { apiEndpoint } = useApiEndpoint();
  const notify = useNotify();

  const downloadExportData = useCallback(async () => {
    try {
      const res = await dataProvider(apiEndpoint).getResourceAction<AnnouncementExportData>("Announcement", "ExportData", { id: props.announcementId });
      if (!res || !res.data) {
        throw new Error("Export data is empty.");
      }

      const jsonString = JSON.stringify(humps.decamelizeKeys(res.data), null, 2);
      const blob = new Blob([jsonString], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `AnnouncementExport_${res.data.title}_${FormatSimpleDateToJST(new Date(res.data.openAt))}-${FormatSimpleDateToJST(new Date(res.data.closeAt))}_${FormatSimpleDateToJST()}.json`;
      document.body.appendChild(link);
      link.style.display = "none";
      link.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (e: unknown) {
      notify(e instanceof Error ? e.message : "Failed to export data.", { type: "warning" });
    }
  }, [apiEndpoint, notify]);

  return (
    <IconButton onClick={downloadExportData} size="small" color="primary">
      <FileDownloadOutlined />
    </IconButton>
  );
};

const ImportButton = () => {
  const { setValue } = useFormContext();
  const notify = useNotify();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const setFormValues = useCallback(
    (data: AnnouncementExportData) => {
      setValue("Title", data.title);
      setValue("Category", data.category);
      setValue("Tag", data.tag);
      setValue("Body", data.body);

      if (process.env.REACT_APP_ENV !== "prod") {
        setValue("OpenAt", data.openAt);
        setValue("CloseAt", data.closeAt);
      }

      const imageDecodedErrors: Error[] = [];

      if (data.banner && data.banner.fileName) {
        const bannerImage = base64ToImageFile(data.banner.data, data.banner.fileName);
        if (bannerImage) {
          setValue("Banner", { src: data.banner.fileName, rawFile: bannerImage });
        } else {
          imageDecodedErrors.push(new Error(`Failed to import banner image. src: ${data.banner.fileName}`));
        }
      }

      if (data.thumbnail.fileName) {
        const thumbnailImage = base64ToImageFile(data.thumbnail.data, data.thumbnail.fileName);
        if (thumbnailImage) {
          const previewUrl = URL.createObjectURL(thumbnailImage);
          setValue("Thumbnail", { src: previewUrl, rawFile: thumbnailImage });
        } else {
          imageDecodedErrors.push(new Error(`Failed to import thumbnail image. src: ${data.thumbnail.fileName}`));
        }
      }

      if (data.images.length > 0) {
        const imageFiles = data.images.map((img) => {
          const file = base64ToImageFile(img.data, img.fileName);
          if (!file) {
            imageDecodedErrors.push(new Error(`Failed to import image. src: ${img.fileName}`));
            return null;
          }
          const previewUrl = URL.createObjectURL(file);
          return { src: previewUrl, rawFile: file };
        });
        if (!imageFiles.includes(null)) {
          setValue("Images", imageFiles);
        }
      }

      if (imageDecodedErrors.length > 0) {
        console.warn(imageDecodedErrors);
        throw new Error("Failed to import image files.");
      }
    },
    [setValue]
  );

  const handleFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) return;

      try {
        const jsonString = await file.text();
        const data = humps.camelizeKeys(JSON.parse(jsonString)) as AnnouncementExportData;
        setFormValues(data);
        notify("Imported data successfully.", { type: "info" });
      } catch (e: unknown) {
        notify(e instanceof Error ? e.message : "Failed to read file.", { type: "warning" });
      }
    },
    [notify, setFormValues]
  );

  return (
    <>
      <MuiButton startIcon={<Publish />} onClick={handleButtonClick}>
        Import
      </MuiButton>
      <input type="file" accept=".json" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
    </>
  );
};

const base64ToImageFile = (base64String: string, fileName: string): File | null => {
  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex === -1) {
    console.warn("Invalid file name format.");
    return null;
  }

  let mimeType = fileName.substring(lastDotIndex + 1);
  if (mimeType === "jpg") {
    mimeType = "jpeg";
  }

  if (!["jpeg", "png"].includes(mimeType)) {
    console.warn("Invalid file type.");
    return null;
  }

  const bin = window.atob(base64String.replace(/^.*,/, ""));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }

  return new File([buffer.buffer], fileName, { type: `image/${mimeType}` });
};
