import { Datagrid, TextField, NumberInput, ListProps } from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField, NumField } from "../Common";

const userActStartTimeFilters = [
  <NumberInput key="slotNumber" source="SlotNumber" label="スロットNO" />,
  <DateTimeWithSecInput key="startedAtFrom" source="StartedAt_from" label="開始日時From" />,
  <DateTimeWithSecInput key="startedAtTo" source="StartedAt_to" label="開始日時To" />,
  <NumberInput key="actLevel" source="ActLevel" label="ACTレベル" />,
  <DateTimeWithSecInput key="lastReceivedAtFrom" source="LastReceivedAt_from" label="最終受取日時From" />,
  <DateTimeWithSecInput key="lastReceivedAtTo" source="LastReceivedAt_to" label="最終受取日時To" />,
];

export const UserActStartTimeList = (props: ListProps) => (
  <CommonList {...props} addFilters={userActStartTimeFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="SlotNumber" label="スロットNO" />
      <YMDHMSDateField source="StartedAt" label="開始日時" />
      <NumField source="ActLevel" label="ACTレベル" />
      <YMDHMSDateField source="LastReceivedAt" label="最終受取日時" />
    </Datagrid>
  </CommonList>
);
