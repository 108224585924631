import { CreateButton, Datagrid, EditButton, FunctionField, Labeled, SelectInput, Show, ShowButton, SimpleShowLayout, TextField, TextInput, useReference } from "react-admin";
import { Alert, Box, Divider, List, ListItem, Typography } from "@mui/material";
import { YMDHMDateField } from "../Common";
import { calcBanState } from "../UserBan";
import { Platforms } from "../../config/platform";
import { CommonList, NumField } from "../Common";
import { Platforms as PlatformNames } from "../../common/Platform";

const userFilters = [
  <SelectInput
    key="searchType"
    source="SearchType"
    label="検索方法"
    choices={[
      { id: "UserId", name: "ユーザーID" },
      { id: "UserName", name: "ユーザー名" },
      { id: "BirthMonth", name: "年齢確認生年月日" },
      { id: "UserCreatedAt", name: "ユーザー作成日時" },
      { id: "LastLoginAt", name: "最終ログイン日時" },
    ]}
    alwaysOn
  />,
  <TextInput key="searchValue" source="SearchValue" label="検索値" alwaysOn />,
  <TextInput key="searchRangeFrom" source="SearchRangeFrom" label="検索範囲From" alwaysOn />,
  <TextInput key="searchRangeTo" source="SearchRangeTo" label="検索範囲To" alwaysOn />,
  <SelectInput key="platform" source="Platform" label="プラットフォーム" choices={Platforms} />,
];

export const UserList = (props: any) => (
  <CommonList {...props} filters={userFilters}>
    <>
      <Alert severity="warning">
        <Typography sx={{ fontSize: 14 }}>・DB負荷が高いため乱用厳禁</Typography>
        <Typography sx={{ fontSize: 14 }}>・ユーザー名は部分一致検索</Typography>
        <Typography sx={{ fontSize: 14 }}>・年齢確認生年月日は「YYYY/MM/DD」形式で入力</Typography>
        <Typography sx={{ fontSize: 14 }}>・ユーザー作成日時、最終ログイン日時は「YYYY/MM/DD HH:MM」形式で検索範囲入力</Typography>
      </Alert>
      <Datagrid bulkActionButtons={false}>
        <TextField source="UserId" label="ユーザーID" />
        <TextField source="UserName" label="ユーザー名" />
        <FunctionField label="年齢確認生年月日" render={(record: any) => <BirthMonthField data={record.UserBirthMonth} />} />
        <YMDHMDateField source="UserCreatedAt" label="ユーザー作成日時" />
        <YMDHMDateField source="LastLoginAt" label="最終ログイン日時" />
        <ShowButton />
      </Datagrid>
    </>
  </CommonList>
);

export const UserShow = () => (
  <Show>
    <SimpleShowLayout divider={<Divider />}>
      <TextField label="ユーザーID" source="id" />
      <TextField label="FireBaseUID" source="UserAccount.FirebaseUid" />
      <TextField label="デバイスセッションID" source="UserAccount.DeviceSessionId" />
      <FunctionField label="プラットフォーム" render={(record: any) => Platforms.find((platform) => platform.id === record.UserAccount?.Platform)?.label || ""} />
      <TextField label="ユーザーネーム" source="UserProfile.Name" />
      <TextField label="コメント" source="UserProfile.Comment" />
      <NumField label="お気に入りキャラカードID" source="UserProfile.FavoriteCharacterCardId" />
      <FunctionField label="誕生日" render={(record: any) => <BirthdayField data={record.UserBirthday} />} />
      <NumField label="ランク" source="UserRank.Rank" />
      <NumField label="経験値" source="UserRank.Exp" />
      <NumField label="AP" source="UserStamina.Stamina" />
      <YMDHMDateField label="AP更新日時" source="UserStamina.LastUpdatedAt" />
      <NumField label="マネー" source="UserMoney.Money" />
      <TextField label="無償ジュエル" source="UserStone.Count" />
      <FunctionField label="有償ジュエル" render={(record: any) => <BilledStonesField data={record.UserBilledStones} />} />
      <FunctionField label="外部決済有償ジュエル" render={(record: any) => record.UserExternalBilledStone?.Count} />
      <FunctionField label="年齢確認生年月日" render={(record: any) => <BirthMonthField data={record.UserBirthMonth} />} />
      <FunctionField label="ホーム設定カード" render={(record: any) => <HomeCardsField data={record.UserHomeCards} />} />
      <FunctionField label="端末情報" render={(record: any) => <DeviceField data={record.UserDeviceHistory} />} />
      <YMDHMDateField label="作成日時" source="User.CreatedAt" />
      <YMDHMDateField label="最終ログイン日時" source="User.LastLoginAt" />
      <FunctionField render={(record: any) => <UserBanField data={record} />} />
    </SimpleShowLayout>
  </Show>
);

const UserBanField = (props: any) => {
  return (
    <>
      <Labeled label="Ban状況">
        <TextField record={{ BanState: calcBanState(props.data.UserBan) }} source="BanState" />
      </Labeled>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "fit-content",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
          bgcolor: "background.paper",
          color: "text.secondary",
          "& hr": {
            mx: 0.5,
          },
        }}
      >
        {(() => {
          if (!props.data.UserBan) {
            return (
              <CreateButton
                to={{
                  pathname: "/UserBan/create",
                  state: { record: { UserId: props.data.id } },
                }}
              />
            );
          } else {
            return <EditButton resource="UserBan" />;
          }
        })()}
      </Box>
    </>
  );
};

const BirthdayField = (props: any) => {
  const day = props.data?.Day;
  const month = props.data?.Month;

  if (day == null || month == null) return null;

  const formattedDay = String(day).padStart(2, "0");
  const formattedMonth = String(month).padStart(2, "0");

  return <Typography sx={{ fontSize: 14 }}>{`${formattedMonth}/${formattedDay}`}</Typography>;
};

const BirthMonthField = (props: any) => {
  const day = props.data?.Day;
  const month = props.data?.Month;
  const year = props.data?.Year;

  if (day == null || month == null || year == null) return null;

  const formattedDay = String(day).padStart(2, "0");
  const formattedMonth = String(month).padStart(2, "0");

  return <Typography sx={{ fontSize: 14 }}>{`${year}/${formattedMonth}/${formattedDay}`}</Typography>;
};

const HomeCardsField = (props: any) => {
  props.data?.sort((a: any, b: any) => a.CardNumber - b.CardNumber);

  return (
    <List dense disablePadding>
      {props.data?.map((userHomeCard: any) => (
        <ListItem key={userHomeCard.CardId}>
          <Typography sx={{ fontSize: 14 }}>
            {"枠: "}
            {userHomeCard.CardNumber}
            {", 種別: "}
            {HomeCardCardTypeString(userHomeCard.CardType)}
            {", カードID: "}
            {userHomeCard.CardId}
            {", カード名: "}
            <HomeCardCardName data={userHomeCard} />
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

function HomeCardCardTypeString(cardType: number) {
  switch (cardType) {
    case 0:
      return "未設定";
    case 1:
      return "キャラクター";
    case 2:
      return "装備";
    default:
      return "未定義";
  }
}

const HomeCardCardName = (props: any) => {
  let reference;
  if (props.data.CardType === 1) {
    reference = "CharacterCard";
  } else {
    reference = "EquipmentCard";
  }
  const { referenceRecord: card, isLoading, error } = useReference({ reference: reference, id: props.data.CardId });
  if (isLoading) {
    return <>Loading ...</>;
  }
  if (error) {
    return <>Error</>;
  }
  return <>{card?.card_name}</>;
};

const DeviceField = (props: any) => {
  if (props.data == null) return null;

  return (
    <List dense disablePadding>
      <ListItem>
        <Typography sx={{ fontSize: 14 }}>機種名: {props.data.DeviceModel}</Typography>
      </ListItem>
      <ListItem>
        <Typography sx={{ fontSize: 14 }}>OS: {props.data.OperatingSystem}</Typography>
      </ListItem>
    </List>
  );
};

const BilledStonesField = (props: any) => {
  return (
    <List dense disablePadding>
      {props.data?.map((userBilledStone: any) => (
        <ListItem key={userBilledStone.Platform}>
          <Typography sx={{ fontSize: 14 }}>
            {`プラットフォーム: ${PlatformNames[userBilledStone.Platform]}`}
            {`, 個数: ${userBilledStone.Count}`}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};
