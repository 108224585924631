import { NumberInput, BooleanInput, BooleanField } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="honorMissionId" source="HonorMissionId" label="称号ミッションID" />,
  <NumberInput key="missionValue" source="MissionValue" label="値" />,
  <NumberInput key="beforeProgress" source="BeforeProgress" label="進捗Before" />,
  <NumberInput key="afterProgress" source="AfterProgress" label="進捗After" />,
  <BooleanInput key="isAchieved" source="IsAchieved" label="達成済み" />,
]);

export const LogUserHonorMissionList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="HonorMissionId" label="称号ミッションID" />
    <NumField source="MissionValue" label="値" />
    <NumField source="BeforeProgress" label="進捗Before" />
    <NumField source="AfterProgress" label="進捗After" />
    <BooleanField source="IsAchieved" label="達成済み" />
  </CommonLogUserList>
);
