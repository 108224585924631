import { CommonList, DateTimeWithSecInput, YMDHMSDateField, NumField } from "components/Common";
import { Datagrid, TextField, NumberInput, BooleanField, BooleanInput, ReferenceField, ListProps } from "react-admin";

const userQuestStoryEpisodeFilters = [
  <NumberInput key="questStoryEpisodeId" source="QuestStoryEpisodeId" label="QuestStoryEpisodeId" />,
  <DateTimeWithSecInput key="readAtFrom" source="ReadAt_from" label="既読日時From" />,
  <DateTimeWithSecInput key="readAtTo" source="ReadAt_to" label="既読日時To" />,
  <BooleanInput key="isFavorite" source="IsFavorite" label="お気に入り" />,
  <BooleanInput key="isBookmarked" source="IsBookmarked" label="ブックマーク" />,
  <DateTimeWithSecInput key="createdAtFrom" source="CreatedAt_from" label="作成日時From" />,
  <DateTimeWithSecInput key="createdAtTo" source="CreatedAt_to" label="作成日時To" />,
];

export const UserQuestStoryEpisodeList = (props: ListProps) => (
  <CommonList {...props} addFilters={userQuestStoryEpisodeFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="QuestStoryEpisodeId" label="QuestStoryEpisodeId" />
      <ReferenceField source="QuestStoryEpisodeId" reference="QuestStoryEpisode" label="タイトル">
        <TextField source="flavor" />
      </ReferenceField>
      <YMDHMSDateField source="ReadAt" label="既読日時" />
      <BooleanField source="IsFavorite" label="お気に入り" />
      <BooleanField source="IsBookmarked" label="ブックマーク" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
