import { Datagrid, NumberInput, TextField } from "react-admin";
import { CommonList, NumField } from "../Common";

const userLastPartyNumberFilters = [<NumberInput key="partyNumber" source="PartyNumber" label="パーティーNO" />];

export const UserLastPartyNumberList = (props: any) => (
  <CommonList {...props} addFilters={userLastPartyNumberFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="PartyNumber" label="パーティーNO" />
    </Datagrid>
  </CommonList>
);
