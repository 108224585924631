import { NumberInput, TextInput, TextField } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="towerStageId" source="TowerStageId" label="タワーステージID" />,
  <TextInput key="battleResult" source="BattleResult" label="バトル結果" />,
  <NumberInput key="deathCount" source="DeathCount" label="戦闘不能回数" />,
  <NumberInput key="turnCount" source="TurnCount" label="ターン数" />,
]);

// TODO JSON field
export const LogUserTowerBattleFinishList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="TowerStageId" label="タワーステージID" />
    <TextField source="BattleResult" label="バトル結果" />
    <NumField source="DeathCount" label="戦闘不能回数" />
    <NumField source="TurnCount" label="ターン数" />
  </CommonLogUserList>
);
