import React from "react";
import { Layout, Sidebar, useSidebarState } from "react-admin";
import { makeStyles } from "@mui/styles";
import { MyMenu } from "../Menu";
import { MyAppBar } from "../AppBar";

const useStyles = makeStyles({
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: 240,
    transition: "width 0.3s ease-in-out",
    overflowX: "hidden",
    "&.closed": {
      width: 57,
    },
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
});

const MySidebar = (props: any) => {
  const classes = useStyles();
  const [open] = useSidebarState();

  return (
    <Sidebar
      {...props}
      classes={{
        paper: open ? classes.drawerPaper : `${classes.drawerPaper} closed`,
      }}
    />
  );
};

export const MyLayout = (props: any) => <Layout {...props} menu={MyMenu} appBar={MyAppBar} sidebar={MySidebar} />;
