import { Datagrid, TextField, NumberInput } from "react-admin";
import { CommonList, NumField } from "../Common";

const userGachaStampFilters = [<NumberInput key="gachaStampId" source="GachaStampId" label="GachaStampId" />, <NumberInput key="step" source="Step" label="ステップ数" />];

export const UserGachaStampList = (props: any) => (
  <CommonList {...props} addFilters={userGachaStampFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="GachaStampId" label="GachaStampId" />
      <NumField source="Step" label="ステップ数" />
    </Datagrid>
  </CommonList>
);
