import { Datagrid, TextField, NumberInput, BooleanField, BooleanInput } from "react-admin";
import { CommonList, YMDHMSDateField, NumField } from "../Common";

const userLimitedGachaFilters = [
  <NumberInput key="limitedGachaId" source="LimitedGachaId" label="LimitedGachaId" />,
  <BooleanInput key="isActive" source="IsActive" label="有効" />,
  <BooleanInput key="isDisplayable" source="IsDisplayable" label="表示可能" />,
];

export const UserLimitedGachaList = (props: any) => (
  <CommonList {...props} addFilters={userLimitedGachaFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="LimitedGachaId" label="LimitedGachaId" />
      <BooleanField source="IsActive" label="有効" />
      <YMDHMSDateField source="StartedAt" label="開始日時" />
      <BooleanField source="IsDisplayable" label="表示可能" />
    </Datagrid>
  </CommonList>
);
