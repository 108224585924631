import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField, YMDHMSDateField, DateTimeWithSecInput } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="slotNumber" source="SlotNumber" label="スロット番号" />,
  <NumberInput key="characterType" source="CharacterType" label="キャラクタータイプ" />,
  <NumberInput key="actLevel" source="ActLevel" label="アクトレベル" />,
  <DateTimeWithSecInput key="startedAt_from" source="StartedAt_from" label="開始時刻From" />,
  <DateTimeWithSecInput key="startedAt_to" source="StartedAt_to" label="開始時刻To" />,
  <DateTimeWithSecInput key="lastReceivedAtFrom" source="LastReceivedAt_from" label="最終受取時刻From" />,
  <DateTimeWithSecInput key="lastReceivedAtTo" source="LastReceivedAt_to" label="最終受取時刻To" />,
]);

export const LogUserActStartTimeList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="SlotNumber" label="スロット番号" />
    <NumField source="CharacterType" label="キャラクタータイプ" />
    <NumField source="ActLevel" label="アクトレベル" />
    <YMDHMSDateField source="StartedAt" label="開始時刻" />
    <YMDHMSDateField source="LastReceivedAt" label="最終受取時刻" />
  </CommonLogUserList>
);
