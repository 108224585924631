import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([<NumberInput key="characterId" source="CharacterId" label="キャラクターID" />]);

export const LogUserBirthdayLoginBonusList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="CharacterId" label="キャラクターID" />
  </CommonLogUserList>
);
