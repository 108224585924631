import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="normalLoginBonusSeasonId" source="NormalLoginBonusSeasonId" label="通常ログインボーナスシーズンID" />,
  <NumberInput key="receivedCount" source="ReceivedCount" label="受取回数" />,
]);

export const LogUserNormalLoginBonusList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="NormalLoginBonusSeasonId" label="通常ログインボーナスシーズンID" />
    <NumField source="ReceivedCount" label="受取回数" />
  </CommonLogUserList>
);
