import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([<NumberInput key="questBattleDifficultyId" source="QuestBattleDifficultyId" label="クエストバトル難易度ID" />]);

export const LogUserBattleRetireList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="QuestBattleDifficultyId" label="クエストバトル難易度ID" />
  </CommonLogUserList>
);
