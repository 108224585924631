import { AppBar, Logout, MenuItemLink, UserMenu } from "react-admin";
import { Typography, Select, MenuItem } from "@mui/material";
import { useApiEndpoint } from "../../providers/apiEndpointProvider";
import { AdminApiEndpoints } from "../../utils/endpoint";
import SettingsIcon from "@mui/icons-material/Settings";

const ClearLocalStorageButton = () => {
  const handleClearLocalStorage = () => {
    localStorage.clear();
    alert("ローカルストレージをクリアしました。");
  };

  return <MenuItemLink to="#" primaryText="ローカルストレージをクリア" leftIcon={<SettingsIcon />} onClick={handleClearLocalStorage} />;
};

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>
    <Logout />
    <ClearLocalStorageButton />
  </UserMenu>
);

export const MyAppBar = (props: any) => {
  const { apiEndpoint, setApiEndpoint } = useApiEndpoint();

  const handleSelectChange = (event: any) => {
    setApiEndpoint(event.target.value);
  };

  const apiEndpoints = AdminApiEndpoints();

  return (
    <AppBar {...props} userMenu={<CustomUserMenu />}>
      <Typography variant="h6" id="react-admin-title" sx={{ flex: 1 }}></Typography>

      <Select value={apiEndpoint} onChange={handleSelectChange} size="small">
        {apiEndpoints?.map((endpoint: any, index: any) => (
          <MenuItem key={index + 1} value={index}>
            {endpoint}
          </MenuItem>
        ))}
      </Select>
    </AppBar>
  );
};
