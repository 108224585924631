import React, { useEffect, useState } from "react";
import { useDataProvider, Title } from "react-admin";
import { useReactTable, getCoreRowModel, getSortedRowModel, getFilteredRowModel, flexRender, SortingState, filterFns } from "@tanstack/react-table";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, TextField, Box, Typography, Button } from "@mui/material";
import { rankItem } from "@tanstack/match-sorter-utils";
import { makeStyles } from "@mui/styles";

interface DataTableProps {
  resource: string;
  label?: string;
}

const useStyles = makeStyles({
  tableContainer: {
    height: "calc(100vh - 64px)",
    display: "flex",
    flexDirection: "column",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1000,
    backgroundColor: "#d3d3d3",
    borderBottom: "1px solid #ddd",
  },
  stickyFilter: {
    position: "sticky",
    top: 0,
    zIndex: 1100,
    backgroundColor: "#fff",
    padding: "5px 10px",
    borderBottom: "1px solid #ddd",
  },
  tableWrapper: {
    flexGrow: 1,
    overflowY: "auto",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#f9f9f9",
    },
  },
  tableHeader: {
    backgroundColor: "#d3d3d3",
    color: "#000",
    minHeight: "32px",
    padding: "4px 8px",
  },
  tableFilter: {
    width: "100%",
    margin: "4px 0",
  },
});

export const DataTable: React.FC<DataTableProps> = ({ resource, label }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [data, setData] = useState<any[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<SortingState>([]);

  const tableWrapperRef = React.useRef<HTMLDivElement>(null);

  const resetScroll = () => {
    if (tableWrapperRef.current) {
      tableWrapperRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const resetFilters = () => {
    setGlobalFilter("");
    table.getAllColumns().forEach((column) => {
      column.setFilterValue("");
    });
  };

  useEffect(() => {
    dataProvider.getList(resource, { pagination: { page: 1, perPage: 999999999 }, sort: { field: "id", order: "ASC" }, filter: {} }).then(({ data }) => setData(data));
  }, [dataProvider, resource]);

  const columns =
    data.length > 0
      ? Object.keys(data[0]).map((key) => ({
          header: key,
          accessorKey: key,
          filterFn: filterFns.includesString,
        }))
      : [];

  const table = useReactTable({
    data,
    columns,
    state: { sorting, globalFilter },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: (row, columnId, filterValue) => {
      const itemRank = rankItem(row.getValue(columnId), filterValue);
      return itemRank.passed;
    },
  });

  return (
    <div className={classes.tableContainer}>
      {label && <Title title={label} />}
      <Box className={classes.stickyFilter} display="flex" alignItems="center" gap={2}>
        <TextField
          label="Search"
          variant="outlined"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          style={{ flex: 1, maxWidth: "300px" }}
          className={classes.tableFilter} // 余白を適用
        />
        <Typography variant="body2">件数: {table.getFilteredRowModel().rows.length}</Typography>
        <Button variant="contained" onClick={resetScroll}>
          TOP
        </Button>
        <Button variant="contained" onClick={resetFilters}>
          Reset
        </Button>
      </Box>
      <TableContainer component={Paper} className={classes.tableWrapper} ref={tableWrapperRef}>
        <Table stickyHeader>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className={classes.tableHeader}>
                {headerGroup.headers.map((column) => (
                  <TableCell key={column.id} className={classes.tableHeader} sortDirection={column.column.getIsSorted() ? column.column.getIsSorted() : false}>
                    <TableSortLabel active={!!column.column.getIsSorted()} direction={column.column.getIsSorted() || "asc"} onClick={column.column.getToggleSortingHandler()}>
                      {flexRender(column.column.columnDef.header, column.getContext())}
                    </TableSortLabel>
                    <Box>
                      <TextField
                        variant="outlined"
                        size="small"
                        className={classes.tableFilter}
                        value={column.column.getFilterValue() || ""}
                        onChange={(e) => column.column.setFilterValue(e.target.value)}
                        placeholder={`Search ${column.column.columnDef.header}`}
                      />
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} className={classes.tableRow}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
