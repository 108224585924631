import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import authProvider from "./authProvider";
import { AdminApiEndpoint, DevToolApiEndpoint } from "../utils/endpoint";
import humps from "humps";

const httpClient = (url: string, options: any = {}): Promise<any> => {
  // Admin API, DevTool API のリクエストヘッダーに認証トークンを付与する
  return authProvider.getJWTToken().then(function (token: string) {
    options.user = {
      authenticated: !!token,
      token: token,
    };

    return fetchUtils.fetchJson(url, options);
  });
};

type GetListQuery = {
  sort: string;
  range: string;
  filter: string;
  meta?: string;
};

interface Params {
  id: string;
  [key: string]: any;
}

const dataProvider = (endPointKey: number) => ({
  getList: async (resource: any, params: any): Promise<any> => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query: GetListQuery = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };

    if (params.meta) {
      query.meta = JSON.stringify(params.meta);
    }

    if (resource === "AnnouncementV130") {
      resource = "Announcement";
    }

    const url = `${AdminApiEndpoint(endPointKey)}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      const totalRange: string = headers.get("content-range") ?? "";
      return {
        data: json,
        total: parseInt(totalRange.split("/").pop() ?? "", 10),
      };
    });
  },

  getOne: async (resource: any, params: any): Promise<any> => {
    if (resource === "AnnouncementV130") {
      resource = "Announcement";
    }

    return httpClient(`${AdminApiEndpoint(endPointKey)}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    }));
  },

  getMany: async (resource: any, params: any): Promise<any> => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${AdminApiEndpoint(endPointKey)}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => {
      json.forEach((element: any) => {
        if (Object.prototype.hasOwnProperty.call(element, "Id")) {
          element.id = element.Id;
        }
      });

      return {
        data: json,
      };
    });
  },

  getManyReference: async (resource: any, params: any): Promise<any> => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${AdminApiEndpoint(endPointKey)}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => {
      const totalRange: string = headers.get("content-range") ?? "";
      return {
        data: json,
        total: parseInt(totalRange.split("/").pop() ?? "", 10),
      };
    });
  },

  create: async (resource: any, params: any): Promise<any> => {
    if (resource === "AnnouncementV130") {
      resource = "Announcement";
    }

    return httpClient(`${AdminApiEndpoint(endPointKey)}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: json,
    }));
  },

  createMany: async (resource: any, params: any): Promise<any> =>
    httpClient(`${AdminApiEndpoint(endPointKey)}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: json,
    })),

  update: async (resource: any, params: any): Promise<any> => {
    if (resource === "AnnouncementV130") {
      resource = "Announcement";
    }

    return httpClient(`${AdminApiEndpoint(endPointKey)}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: async (resource: any, params: any): Promise<any> => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${AdminApiEndpoint(endPointKey)}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  delete: async (resource: any, params: any): Promise<any> => {
    if (resource === "AnnouncementV130") {
      resource = "Announcement";
    }

    return httpClient(`${AdminApiEndpoint(endPointKey)}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json }));
  },

  deleteMany: async (resource: any, params: any): Promise<any> => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${AdminApiEndpoint(endPointKey)}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  debug: async (api: string, method: string, params: any): Promise<any> => {
    let url = `${DevToolApiEndpoint(endPointKey)}/${api}`;
    const options: any = {
      method: method,
    };

    if (method === "POST" || method === "PUT") {
      options.body = params;
    } else {
      url += "/" + Object.values(JSON.parse(params)).join("/");
    }

    return httpClient(url, options).then(({ json }) => ({ data: json }));
  },

  getResourceAction: async <T>(resource: string, action: string, params: Params): Promise<{ data: T }> => {
    let url = `${AdminApiEndpoint(endPointKey)}/${resource}/${params.id}/${action}`;

    const queryParams = new URLSearchParams();
    for (const key in params) {
      if (key !== "id") {
        queryParams.append(key, params[key]);
      }
    }

    const queryString = queryParams.toString();
    if (queryString) {
      url += `?${queryString}`;
    }

    return httpClient(url).then(({ json }) => ({ data: humps.camelizeKeys(json) as T }));
  },
});

export default dataProvider;
