import { Datagrid, TextField, TextInput, ListProps } from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField } from "../Common";

const userCommonBilledHistoryFilters = [
  <TextInput key="id" source="Id" label="ID" />,
  <TextInput key="productId" source="ProductId" label="プロダクトID" />,
  <DateTimeWithSecInput key="createdAtFrom" source="CreatedAt_from" label="作成日時From" />,
  <DateTimeWithSecInput key="createdAtTo" source="CreatedAt_to" label="作成日時To" />,
];

export const UserCommonBilledHistoryList = (props: ListProps) => (
  <CommonList {...props} addFilters={userCommonBilledHistoryFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="Id" label="ID" />
      <TextField source="UserId" label="ユーザーID" />
      <TextField source="ProductId" label="プロダクトID" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
