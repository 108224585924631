import { Datagrid, TextField, NumberInput } from "react-admin";
import { CommonList, NumField } from "../Common";

const userStartedMissionGroupFilters = [<NumberInput key="missionGroupId" source="MissionGroupId" label="MissionGroupId" />];

export const UserStartedMissionGroupList = (props: any) => (
  <CommonList {...props} addFilters={userStartedMissionGroupFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="MissionGroupId" label="MissionGroupId" />
    </Datagrid>
  </CommonList>
);
