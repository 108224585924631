import { Datagrid, TextField, TextInput, ListProps } from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField } from "../Common";

const userAppStoreBilledHistoryFilters = [
  <TextInput key="transactionId" source="TransactionId" label="トランザクションID" />,
  <TextInput key="productId" source="ProductId" label="プロダクトID" />,
  <DateTimeWithSecInput key="createdAtFrom" source="CreatedAt_from" label="作成日時From" />,
  <DateTimeWithSecInput key="createdAtTo" source="CreatedAt_to" label="作成日時To" />,
];

export const UserAppStoreBilledHistoryList = (props: ListProps) => (
  <CommonList {...props} addFilters={userAppStoreBilledHistoryFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <TextField source="TransactionId" label="トランザクションID" />
      <TextField source="ProductId" label="プロダクトID" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
