import React from "react";
import { Datagrid, TextField, TextInput, ListProps } from "react-admin";
import { CommonList, YMDHMSDateField, DateTimeWithSecInput } from "../Common";

const filters = [
  <TextInput key="Id" source="Id" label="ID" sx={{ width: "350px" }} />,
  <TextInput key="RecoveryUserId" source="RecoveryUserId" label="復旧対象ユーザーID" sx={{ width: "350px" }} />,
  <TextInput key="RecoveryFirebaseUid" source="RecoveryFirebaseUid" label="復旧対象FirebaseUID" sx={{ width: "350px" }} />,
  <TextInput key="NewUserId" source="NewUserId" label="新規作成ユーザーID" sx={{ width: "350px" }} />,
  <TextInput key="NewFirebaseUid" source="NewFirebaseUid" label="新規作成FirebaseUID" sx={{ width: "350px" }} />,
  <DateTimeWithSecInput key="CreatedAt_from" source="CreatedAt_from" label="作成日時From" />,
  <DateTimeWithSecInput key="CreatedAt_to" source="CreatedAt_to" label="作成日時To" />,
];

export const LogUserAccountRecoveryAdminActionList = (props: ListProps) => (
  <CommonList {...props} sort={{ field: "CreatedAt", order: "DESC" }} filters={filters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="Id" label="ID" />
      <TextField source="RecoveryUserId" label="復旧対象ユーザーID" />
      <TextField source="RecoveryFirebaseUid" label="復旧対象FirebaseUID" />
      <TextField source="NewUserId" label="新規作成ユーザーID" />
      <TextField source="NewFirebaseUid" label="新規作成FirebaseUID" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
