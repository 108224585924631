import { Datagrid, TextField, NumberInput, BooleanField, BooleanInput, ReferenceField, ListProps } from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField, NumField } from "../Common";

const userHonorFilters = [
  <NumberInput key="honorId" source="HonorId" label="HonorId" />,
  <DateTimeWithSecInput key="receivedAtFrom" source="ReceivedAt_from" label="受取日時From" />,
  <DateTimeWithSecInput key="receivedAtTo" source="ReceivedAt_to" label="受取日時To" />,
  <BooleanInput key="isNew" source="IsNew" label="NEW" />,
  <DateTimeWithSecInput key="createdAtFrom" source="CreatedAt_from" label="作成日時From" />,
  <DateTimeWithSecInput key="createdAtTo" source="CreatedAt_to" label="作成日時To" />,
];

export const UserHonorList = (props: ListProps) => (
  <CommonList {...props} addFilters={userHonorFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="HonorId" label="HonorId" />
      <ReferenceField source="HonorId" reference="Honor" label="称号名">
        <TextField source="name" />
      </ReferenceField>
      <YMDHMSDateField source="ReceivedAt" label="受取日時" />
      <BooleanField source="IsNew" label="NEW" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
