import { Button, CreateButton, ExportButton, FilterButton, TopToolbar, useRefresh, useResourceDefinition } from "react-admin";
import RefreshIcon from "@mui/icons-material/Refresh";
import { WildcardHelpButton } from "./index";

export const CommonActions = ({ showWildcardHelp = false }: { showWildcardHelp?: boolean }) => {
  const resource = useResourceDefinition();
  const refresh = useRefresh();

  const handleRefresh = () => {
    refresh();
  };

  return (
    <TopToolbar>
      <WildcardHelpButton show={showWildcardHelp} />
      <FilterButton />
      {resource.hasCreate && <CreateButton />}
      <ExportButton />
      <Button label="再表示" onClick={handleRefresh} startIcon={<RefreshIcon />} />
    </TopToolbar>
  );
};
