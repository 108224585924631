import React from "react";
import { Datagrid, TextField, TextInput, ListProps, BooleanField, BooleanInput, NumberInput, FunctionField } from "react-admin";
import { CommonList, YMDHMSDateField, DateTimeWithSecInput, NumField } from "../Common";
import { Platforms } from "../../common/Platform";

const filters = [
  <TextInput key="Id" source="Id" label="ID" sx={{ width: "350px" }} />,
  <TextInput key="UserId" source="UserId" label="ユーザーID" sx={{ width: "350px" }} />,
  <NumberInput key="platform" source="Platform" label="プラットフォーム" />,
  <TextInput key="transactionId" source="TransactionId" label="トランザクションID" />,
  <TextInput key="productId" source="ProductId" label="プロダクトID" />,
  <BooleanInput key="IsCompensated" source="IsCompensated" label="補填済" />,
  <DateTimeWithSecInput key="CreatedAt_from" source="CreatedAt_from" label="作成日時From" />,
  <DateTimeWithSecInput key="CreatedAt_to" source="CreatedAt_to" label="作成日時To" />,
];

export const LogUserPurchaseMultipleList = (props: ListProps) => (
  <>
    <p>二重決済してしまったユーザーのログ</p>
    <CommonList {...props} sort={{ field: "CreatedAt", order: "DESC" }} filters={filters}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="Id" label="ID" />
        <TextField source="UserId" label="ユーザーID" />
        <FunctionField label="プラットフォーム" render={(r: any) => `${r.Platform}: ${Platforms[r.Platform]}`} />
        <TextField source="TransactionId" label="トランザクションID" />
        <TextField source="ProductId" label="プロダクトID" />
        <BooleanField source="IsCompensated" label="補填済" />
        <YMDHMSDateField source="CreatedAt" label="作成日時" />
      </Datagrid>
    </CommonList>
  </>
);
