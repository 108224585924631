import { NumberInput, BooleanInput, BooleanField } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="towerStageId" source="TowerStageId" label="タワーステージID" />,
  <BooleanInput key="isPlayed" source="IsPlayed" label="プレイ済み" />,
  <BooleanInput key="isCleared" source="IsCleared" label="クリア済み" />,
  <NumberInput key="score" source="Score" label="スコア" />,
]);

export const LogUserTowerStageList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="TowerStageId" label="タワーステージID" />
    <BooleanField source="IsPlayed" label="プレイ済み" />
    <BooleanField source="IsCleared" label="クリア済み" />
    <NumField source="Score" label="スコア" />
  </CommonLogUserList>
);
