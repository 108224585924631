import { BooleanField, BooleanInput, Datagrid, NumberInput, TextField } from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField, NumField } from "../Common";

const logUserGachaContentFilters = [
  <NumberInput key="purchaseNumber" source="PurchaseNumber" label="NO" />,
  <NumberInput key="count" source="Count" label="回数" />,
  <NumberInput key="gachaId" source="GachaId" label="GachaId" />,
  <NumberInput key="gachaContentId" source="GachaContentId" label="GachaContentId" />,
  <NumberInput key="cardType" source="CardType" label="カードタイプ" />,
  <NumberInput key="cardId" source="CardId" label="カードID" />,
  <BooleanInput key="isConverted" source="IsConverted" label="ピース変換" />,
  <DateTimeWithSecInput key="createdAtFrom" source="CreatedAt_from" label="作成日時From" />,
  <DateTimeWithSecInput key="createdAtTo" source="CreatedAt_to" label="作成日時To" />,
];

export const LogUserGachaContentList = (props: any) => (
  <CommonList {...props} addFilters={logUserGachaContentFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="PurchaseNumber" label="NO" />
      <NumField source="Count" label="回数" />
      <NumField source="GachaId" label="GachaId" />
      <NumField source="GachaContentId" label="GachaContentId" />
      <NumField source="CardType" label="カードタイプ" />
      <NumField source="CardId" label="カードID" />
      <BooleanField source="IsConverted" label="ピース変換" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
