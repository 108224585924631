import React, { useState } from "react";
import { Datagrid, TextField, TextInput, FunctionField } from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField } from "../Common";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const logAdminAccessListFilters = [
  <DateTimeWithSecInput key="from" label="日時From" source="CreatedAt_from" />,
  <DateTimeWithSecInput key="to" label="日時To" source="CreatedAt_to" />,
  <TextInput key="mailAddress" source="MailAddress" label="メールアドレス" />,
  <TextInput key="requestPath" source="RequestPath" label="パス" />,
  <TextInput key="method" source="Method" label="リクエストメソッド" />,
];

export const LogAdminAccessList = (props: any) => {
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

  const handleToggle = (id: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <CommonList {...props} filters={logAdminAccessListFilters} sort={{ field: "CreatedAt", order: "DESC" }}>
      <Datagrid bulkActionButtons={false}>
        <YMDHMSDateField source="CreatedAt" label="日時" />
        <TextField source="MailAddress" label="メールアドレス" />
        <TextField source="RequestPath" label="パス" />
        <TextField source="Method" label="リクエストメソッド" />
        <FunctionField
          label="ボディ"
          render={(record: any) => {
            if (!record || !record.RequestBody) {
              return null;
            }

            const isExpanded = expandedRows[record.id] || false;

            return (
              <div>
                <Button variant="outlined" color="primary" onClick={() => handleToggle(record.id)} endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                  {isExpanded ? "閉じる" : "表示"}
                </Button>
                {isExpanded && <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{JSON.stringify(JSON.parse(record.RequestBody), null, 2)}</pre>}
              </div>
            );
          }}
        />
      </Datagrid>
    </CommonList>
  );
};
