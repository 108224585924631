import { Datagrid, TextField, NumberInput, ListProps } from "react-admin";
import { CommonList, YMDHMSDateField, NumField } from "../Common";

const userNormalLoginBonusFilters = [<NumberInput key="receivedCount" source="ReceivedCount" label="受取回数" />];

export const UserNormalLoginBonusList = (props: ListProps) => (
  <CommonList {...props} addFilters={userNormalLoginBonusFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="ReceivedCount" label="受取回数" />
      <YMDHMSDateField source="LastReceiveAt" label="最終受取日時" />
    </Datagrid>
  </CommonList>
);
