import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField, ReferenceCharacterCardField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="characterCardId" source="CharacterCardId" label="カードID" />,
  <NumberInput key="exp" source="Exp" label="Exp" />,
  <NumberInput key="beforeExp" source="BeforeExp" label="ExpBefore" />,
  <NumberInput key="afterExp" source="AfterExp" label="ExpAfter" />,
]);

export const LogUserCharacterCardExpList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="CharacterCardId" label="カードID" />
    <ReferenceCharacterCardField label="MCカード名" />
    <NumField source="Exp" label="Exp" />
    <NumField source="BeforeExp" label="ExpBefore" />
    <NumField source="AfterExp" label="ExpAfter" />
  </CommonLogUserList>
);
