import { Datagrid, TextField, NumberInput, ListProps } from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField, NumField } from "../Common";

const userBirthdayLoginBonusFilters = [
  <NumberInput key="characterId" source="CharacterId" label="CharacterId" />,
  <DateTimeWithSecInput key="lastReceiveAtFrom" source="LastReceiveAt_from" label="最終受取日時From" />,
  <DateTimeWithSecInput key="lastReceiveAtTo" source="LastReceiveAt_to" label="最終受取日時To" />,
];

export const UserBirthdayLoginBonusList = (props: ListProps) => (
  <CommonList {...props} addFilters={userBirthdayLoginBonusFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="CharacterId" label="CharacterId" />
      <YMDHMSDateField source="LastReceiveAt" label="最終受取日時" />
    </Datagrid>
  </CommonList>
);
