import { CheckboxGroupInput, Create, Datagrid, Edit, EditButton, FunctionField, List, SimpleForm, maxLength, SelectInput, useRecordContext } from "react-admin";
import { DateTimeWithSecInput, EditToolbar, EditTitle, TextWithBreaksField, TextWithCharCountInput, YMDHMSDateField, CommonActions } from "../Common";
import { CurrentDate, FormatDate } from "../../utils/date";
import { Chip } from "@mui/material";
import { Platforms } from "../../config/platform";

const messageMaxLength = 255;

const serviceNames = [
  { id: "ShopService", name: "ショップ" },
  { id: "GachaService", name: "ガチャ" },
  { id: "PvpService", name: "Pvp" },
  { id: "MissionService", name: "ミッション" },
  { id: "TowerService", name: "タワーバトル" },
  { id: "BoxGachaService", name: "BOXガチャ" },
];

const ServiceMaintenanceStatusField = (props: any) => {
  const record = useRecordContext();

  const currentDate = CurrentDate();
  const isOpen = currentDate >= record?.OpenAt && currentDate <= record?.CloseAt;
  return <div style={{ color: isOpen ? "red" : "green" }}>{isOpen ? "メンテナンス中" : "メンテナンス外"}</div>;
};

const transformMaintenanceEdit = (data: any) => {
  return {
    ...data,
    Platforms: data.Platforms.map((value: any) => parseInt(value, 10)),
  };
};

const renderPlatforms = (record: any) => {
  if (!record || !record.Platforms) {
    return null;
  }

  return (
    <div>
      {record.Platforms.map((id: number) => {
        const choice = Platforms.find((choice) => choice.id === id);
        return choice ? <Chip key={id} label={choice.label} style={{ margin: 2 }} /> : id;
      })}
    </div>
  );
};

const validateServiceMaintenanceEdit = (values: any) => {
  const errors = {} as any;

  const openAt = FormatDate(values.OpenAt);
  const closeAt = FormatDate(values.CloseAt);

  if (openAt >= closeAt) {
    errors.OpenAt = "OpenAtはCloseAtより前でなければなりません";
    errors.CloseAt = "CloseAtはOpenAtより後でなければなりません";
  }

  if (maxLength(messageMaxLength)(values.Message, values)) {
    errors.Message = `Messageは${messageMaxLength}文字以下でなければなりません`;
  }
  return errors;
};

export const ServiceMaintenanceList = (props: any) => (
  <List {...props} filters={[]} actions={<CommonActions />} pagination={null} sort={{ field: "CreatedAt", order: "DESC" }} empty={false}>
    <Datagrid bulkActionButtons={false}>
      <YMDHMSDateField label="作成日時" source="CreatedAt" />
      <FunctionField label="機能名" render={(record: any) => serviceNames.find((service) => service.id === record.Name)?.name || ""} />
      <FunctionField label="プラットフォーム" render={renderPlatforms} />
      <YMDHMSDateField label="開始日時" source="OpenAt" />
      <YMDHMSDateField label="終了日時" source="CloseAt" />
      <TextWithBreaksField label="メッセージ" source="Message" sortable={false} />
      <ServiceMaintenanceStatusField label="状態" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);

export const ServiceMaintenanceEdit = (props: any) => (
  <Edit {...props} title={<EditTitle name="機能メンテナンス" />}>
    <SimpleForm validate={validateServiceMaintenanceEdit} toolbar={<EditToolbar />}>
      <SelectInput source="Name" label="機能名" choices={serviceNames} optionText="name" readOnly />
      <CheckboxGroupInput label="プラットフォーム" source="Platforms" choices={Platforms} optionText="label" />
      <DateTimeWithSecInput label="開始日時" source="OpenAt" required />
      <DateTimeWithSecInput label="終了日時" source="CloseAt" required />
      <TextWithCharCountInput label="メッセージ" source="Message" multiline rows="10" maxLength={messageMaxLength} sx={{ width: 500 }} />
    </SimpleForm>
  </Edit>
);

export const ServiceMaintenanceCreate = (props: any) => (
  <Create {...props} redirect="list" transform={transformMaintenanceEdit}>
    <SimpleForm validate={validateServiceMaintenanceEdit}>
      <SelectInput source="Name" label="機能名" choices={serviceNames} optionText="name" />
      <CheckboxGroupInput label="プラットフォーム" source="Platforms" choices={Platforms} defaultValue={["1", "2"]} optionText="label" />
      <DateTimeWithSecInput label="開始日時" source="OpenAt" required />
      <DateTimeWithSecInput label="終了日時" source="CloseAt" required />
      <TextWithCharCountInput label="メッセージ" source="Message" multiline rows="10" maxLength={messageMaxLength} sx={{ width: 500 }} />
    </SimpleForm>
  </Create>
);
