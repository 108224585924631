import { Datagrid, NumberInput, TextField, TextInput } from "react-admin";
import { CommonList, NumField } from "../Common";

const userPartyFilters = [<NumberInput key="partyNumber" source="PartyNumber" label="パーティーNO" />, <TextInput key="partyName" source="PartyName" label="パーティー名" />];

export const UserPartyList = (props: any) => (
  <CommonList {...props} addFilters={userPartyFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="PartyNumber" label="パーティーNO" />
      <TextField source="PartyName" label="パーティー名" />
    </Datagrid>
  </CommonList>
);
