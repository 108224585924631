import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="questBattleDifficultyId" source="QuestBattleDifficultyId" label="クエストバトル難易度ID" />,
  <NumberInput key="continueCount" source="ContinueCount" label="コンティニュー回数" />,
  <NumberInput key="beforeContinueCount" source="BeforeContinueCount" label="コンティニュー前回数" />,
  <NumberInput key="afterContinueCount" source="AfterContinueCount" label="コンティニュー後回数" />,
]);

export const LogUserBattleContinueList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="QuestBattleDifficultyId" label="クエストバトル難易度ID" />
    <NumField source="ContinueCount" label="コンティニュー回数" />
    <NumField source="BeforeContinueCount" label="コンティニュー前回数" />
    <NumField source="AfterContinueCount" label="コンティニュー後回数" />
  </CommonLogUserList>
);
