import { Datagrid, TextField, TextInput, NumberInput } from "react-admin";
import { CommonList, YMDHMSDateField, NumField, ContentReferenceField } from "../Common";

const logUserPresentFilters = [
  <TextInput key="presentId" source="PresentId" label="プレゼントID" />,
  <NumberInput key="contentType" source="ContentType" label="ContentType" />,
  <NumberInput key="contentId" source="ContentId" label="ContentId" />,
  <NumberInput key="contentNum" source="ContentNum" label="ContentNum" />,
  <TextInput key="acquisitionFrom" source="AcquisitionFrom" label="付与元" />,
];

export const LogUserPresentList = (props: any) => (
  <CommonList {...props} addFilters={logUserPresentFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <TextField source="PresentId" label="プレゼントID" />
      <NumField source="ContentType" label="ContentType" />
      <NumField source="ContentId" label="ContentId" />
      <ContentReferenceField label="コンテンツ詳細" />
      <NumField source="ContentNum" label="ContentNum" />
      <TextField source="CustomMessage" label="カスタムメッセージ" />
      <TextField source="AcquisitionFrom" label="付与元" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
