import { Datagrid, TextField, TextInput } from "react-admin";
import { CommonList } from "../Common";

const userAccountMigrationTokenFilters = [<TextInput key="token" source="Token" label="トークン" />];

export const UserAccountMigrationTokenList = (props: any) => (
  <CommonList {...props} addFilters={userAccountMigrationTokenFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="Token" label="トークン" />
      <TextField source="UserId" label="ユーザーID" />
    </Datagrid>
  </CommonList>
);
