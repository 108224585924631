import { Datagrid, TextField, NumberInput } from "react-admin";
import { CommonList, NumField } from "../Common";

const userCharacterPanelFilters = [
  <NumberInput key="characterCardId" source="CharacterCardId" label="CharacterCardId" />,
  <NumberInput key="panelId" source="PanelId" label="PanelId" />,
];

export const UserCharacterPanelList = (props: any) => (
  <CommonList {...props} addFilters={userCharacterPanelFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="CharacterCardId" label="CharacterCardId" />
      <NumField source="PanelId" label="PanelId" />
    </Datagrid>
  </CommonList>
);
