import React, { useState } from "react";
import { Datagrid, FunctionField, NumberInput, TextField, TextInput, ShowButton, Show, SimpleShowLayout, useList, ListContextProvider, useRecordContext } from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField, NumField } from "../Common";
import { ActionTypes } from "../../common/ActionType";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const logUserActionFilters = [
  <TextInput key="id" source="Id" label="Id" />,
  <NumberInput key="actionType" source="ActionType" label="アクションタイプ" />,
  <DateTimeWithSecInput key="createdAtFrom" source="CreatedAt_from" label="作成日時from" />,
  <DateTimeWithSecInput key="createdAtTo" source="CreatedAt_to" label="作成日時to" />,
];

export const LogUserActionList = (props: any) => (
  <CommonList {...props} addFilters={logUserActionFilters} sort={{ field: "CreatedAt", order: "DESC" }}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="Id" label="Id" />
      <TextField source="UserId" label="ユーザーID" />
      <FunctionField label="アクションタイプ" render={(r: any) => `${r.ActionType}: ${ActionTypes[r.ActionType]}`} />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
      <ShowButton />
    </Datagrid>
  </CommonList>
);

export const LogUserActionShow = (props: any) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // 前のページに戻る
  };

  return (
    <Show {...props} resource="LogUserActionDetail">
      <SimpleShowLayout divider={<Divider />}>
        <NumField source="LogUserAction.Id" label="アクションID" />
        <FunctionField label="アクションタイプ" render={(record: any) => `${record.LogUserAction.ActionType}: ${ActionTypes[record.LogUserAction.ActionType]}`} />
        <FunctionField label="アクションログ詳細" render={(record: any) => <LogUserActionDetailList data={record.AdminLogUserActionDetails} />} />
      </SimpleShowLayout>
      <Button onClick={handleBackClick}>戻る</Button>
    </Show>
  );
};

const LogUserActionDetailList = (props: any) => {
  console.log(JSON.stringify(props.data));

  const listContext = useList({ data: props.data });

  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

  const handleToggle = (id: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false}>
        <NumField source="No" label="No" />
        <TextField source="LogUserTable" label="ログ名" />
        <LogIdField source="LogID" label="ログID" />
        <YMDHMSDateField source="CreatedAt" label="作成日時" />
        <FunctionField
          label="生データ"
          render={(record: any) => {
            const isExpanded = expandedRows[record.No] || false;

            return (
              <div>
                <Button variant="outlined" color="primary" onClick={() => handleToggle(record.No)} endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                  {isExpanded ? "閉じる" : "表示"}
                </Button>
                {isExpanded && <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{JSON.stringify(JSON.parse(record.RawData), null, 2)}</pre>}
              </div>
            );
          }}
        />
      </Datagrid>
    </ListContextProvider>
  );
};

export const LogIdField = (props: any) => {
  const record = useRecordContext();
  const navigate = useNavigate();

  const handleClick = () => {
    const filter = JSON.stringify({ Id: record["LogID"] });
    navigate(`/${record["LogUserTable"]}?filter=${encodeURIComponent(filter)}`);
  };

  return (
    <Typography component="span" onClick={handleClick} style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}>
      <TextField {...props} />
    </Typography>
  );
};
