import { Datagrid, TextField, NumberInput, BooleanField, BooleanInput, ReferenceField, ListProps } from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField, NumField } from "../Common";

const userCardStoryEpisodeFilters = [
  <NumberInput key="cardStoryEpisodeId" source="CardStoryEpisodeId" label="CardStoryEpisodeId" />,
  <DateTimeWithSecInput key="readAtFrom" source="ReadAt_from" label="既読日時From" />,
  <DateTimeWithSecInput key="readAtTo" source="ReadAt_to" label="既読日時To" />,
  <BooleanInput key="isFavorite" source="IsFavorite" label="お気に入り" />,
  <BooleanInput key="isBookmarked" source="IsBookmarked" label="ブックマーク" />,
  <DateTimeWithSecInput key="createdAtFrom" source="CreatedAt_from" label="作成日時From" />,
  <DateTimeWithSecInput key="createdAtTo" source="CreatedAt_to" label="作成日時To" />,
];

export const UserCardStoryEpisodeList = (props: ListProps) => (
  <CommonList {...props} addFilters={userCardStoryEpisodeFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="CardStoryEpisodeId" label="CardStoryEpisodeId" />
      <ReferenceField source="CardStoryEpisodeId" reference="CardStoryEpisode" label="タイトル">
        <TextField source="flavor" />
      </ReferenceField>
      <YMDHMSDateField source="ReadAt" label="既読日時" />
      <BooleanField source="IsFavorite" label="お気に入り" />
      <BooleanField source="IsBookmarked" label="ブックマーク" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
