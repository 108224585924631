import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField, YMDHMSDateField, DateTimeWithSecInput } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="slotNumber" source="SlotNumber" label="スロット番号" />,
  <NumberInput key="presentItemId" source="PresentItemId" label="プレゼントアイテムID" />,
  <DateTimeWithSecInput key="presentAtFrom" source="PresentAt_from" label="プレゼント時刻From" />,
  <DateTimeWithSecInput key="presentAtTo" source="PresentAt_to" label="プレゼント時刻To" />,
  <DateTimeWithSecInput key="expiredAtFrom" source="ExpiredAt_from" label="有効期限From" />,
  <DateTimeWithSecInput key="expiredAtTo" source="ExpiredAt_to" label="有効期限To" />,
]);

export const LogUserActPresentItemList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="SlotNumber" label="スロット番号" />
    <NumField source="PresentItemId" label="プレゼントアイテムID" />
    <YMDHMSDateField source="PresentAt" label="プレゼント時刻" />
    <YMDHMSDateField source="ExpiredAt" label="有効期限" />
  </CommonLogUserList>
);
