export const FormatDate = (dateString: string) => {
  if (!dateString) {
    return "";
  }
  const date = new Date(dateString);
  return date.toISOString();
};

export const CurrentDate = () => {
  return new Date().toISOString();
};

export const FormatDateToJST = (date: Date = new Date()): string => {
  const jstDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);

  const year = jstDate.getUTCFullYear();
  const month = String(jstDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(jstDate.getUTCDate()).padStart(2, "0");
  const hours = String(jstDate.getUTCHours()).padStart(2, "0");
  const minutes = String(jstDate.getUTCMinutes()).padStart(2, "0");
  const seconds = String(jstDate.getUTCSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const ToJST = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" });
};

export const FormatSimpleDateToJST = (date: Date = new Date()): string => {
  const jstDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);

  const year = jstDate.getFullYear();
  const month = String(jstDate.getMonth() + 1).padStart(2, "0");
  const day = String(jstDate.getDate()).padStart(2, "0");
  const hour = String(jstDate.getHours()).padStart(2, "0");
  const minute = String(jstDate.getMinutes()).padStart(2, "0");
  return `${year}${month}${day}${hour}${minute}`;
};
