import {
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  Datagrid,
  NumberInput,
  SelectInput,
  Create,
  TextField,
  EditButton,
  SelectField,
  Edit,
  SaveButton,
  Toolbar,
  useNotify,
  useDataProvider,
  WithRecord,
  RaRecord,
  EditProps,
  CreateProps,
  BooleanInput,
  useRefresh,
  required,
  minLength,
} from "react-admin";
import { useMutation } from "react-query";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ContentTypes } from "common/ContentType";
import {
  CommonList,
  DateTimeWithSecInput,
  EditTitle,
  EditToolbar,
  YMDHMSDateField,
  NumField,
  ContentTypeWithUnifiedContentInput,
  NumInput,
  ContentReferenceField,
} from "components/Common";
import { Button, ListProps } from "@mui/material";

const SendToUserButton = (record: RaRecord) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const { mutate, isLoading } = useMutation(
    ["create"],
    async () => {
      return dataProvider.create("TransferPresent", {
        data: {
          present_id: record.PresentId,
          user_id: record.UserId,
          content_type: record.ContentType,
          content_id: record.ContentId,
          content_num: record.ContentNum,
          custom_message: record.CustomMessage,
          open_at: record.OpenAt,
          expired_at: record.ExpiredAt,
        },
      });
    },
    {
      onSuccess: (d: any) => {
        notify("success", { type: "success" });
        refresh();
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  if (record.GrantedAt) {
    return null;
  }

  return (
    <Button onClick={() => mutate()} disabled={isLoading}>
      付与
    </Button>
  );
};

const filters = [
  <TextInput key="presentId" source="PresentId" label="プレゼントID" sx={{ width: "350px" }} />,
  <TextInput key="userId" source="UserId" label="ユーザーID" sx={{ width: "350px" }} />,
  <SelectInput key="contentType" source="ContentType" label="コンテンツタイプ" choices={ContentTypes} />,
  <NumberInput key="contentId" source="ContentId" label="コンテンツID" />,
  <NumberInput key="contentNum" source="ContentNum" label="個数" />,
  <TextInput key="customMessage" source="CustomMessage_like" label="カスタムメッセージ Like" />,
  <DateTimeWithSecInput key="openAtFrom" source="OpenAt_from" label="付与開始日時From" />,
  <DateTimeWithSecInput key="openAtTo" source="OpenAt_to" label="付与開始日時To" />,
  <DateTimeWithSecInput key="expiredAtFrom" source="ExpiredAt_from" label="受取期限From" />,
  <DateTimeWithSecInput key="expiredAtTo" source="ExpiredAt_to" label="受取期限To" />,
  <DateTimeWithSecInput key="grantedAtFrom" source="GrantedAt_from" label="付与済日時From" />,
  <DateTimeWithSecInput key="grantedAtTo" source="GrantedAt_to" label="付与済日時To" />,
  <DateTimeWithSecInput key="createdAtFrom" source="CreatedAt_from" label="作成日時From" />,
  <DateTimeWithSecInput key="createdAtTo" source="CreatedAt_to" label="作成日時To" />,
  <BooleanInput key="grantedAtIsNotNull" source="GrantedAt_isnotnull" label="付与済" alwaysOn />,
  <BooleanInput key="grantedAtIsNull" source="GrantedAt_isnull" label="未付与" alwaysOn />,
];

export const AdminPresentList = (props: ListProps) => (
  <CommonList {...props} filters={filters} sort={{ field: "CreatedAt", order: "DESC" }} showWildcardHelp={true} filterDefaultValues={{ GrantedAt_isnull: true }}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="PresentId" label="プレゼントID" />
      <TextField source="UserId" label="ユーザーID" />
      <SelectField source="ContentType" label="コンテンツタイプ" choices={ContentTypes} />
      <NumField source="ContentId" label="コンテンツID" />
      <ContentReferenceField label="コンテンツ詳細" />
      <NumField source="ContentNum" label="個数" />
      <TextField source="CustomMessage" label="カスタムメッセージ" />
      <YMDHMSDateField source="OpenAt" label="付与開始日時" />
      <YMDHMSDateField source="ExpiredAt" label="受取期限" />
      <YMDHMSDateField source="GrantedAt" label="付与済日時" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
      <WithRecord render={(record) => (record.GrantedAt == null ? <EditButton /> : null)} />
      <WithRecord label="付与" render={(record) => SendToUserButton(record)} />
    </Datagrid>
  </CommonList>
);

export const AdminPresentEdit = (props: EditProps) => (
  <Edit {...props} title={<EditTitle name="プレゼント付与" />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="PresentId" label="プレゼントID" disabled />
      <TextInput source="UserId" label="ユーザーID" disabled />
      <ContentTypeWithUnifiedContentInput />
      <NumInput source="ContentNum" label="個数" validate={required()} />
      <TextInput source="CustomMessage" label="カスタムメッセージ" />
      <DateTimeWithSecInput source="OpenAt" label="付与開始日時" validate={required()} />
      <DateTimeWithSecInput source="ExpiredAt" label="受取期限" validate={required()} />
      <TextInput source="CreatedAt" label="作成日時" disabled sx={{ width: "400px" }} />
    </SimpleForm>
  </Edit>
);

// 基本のtoolbarでは複数作成した場合のレスポンスをparse出来ないためcreateManyを利用するカスタムのツールバーを用意
const AdminPresentCreateToolbar = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { handleSubmit } = useFormContext();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    ["createMany"],
    async (data: any) => {
      return dataProvider.createMany("AdminPresent", {
        data: {
          user_ids: data.UserIDs,
          content_type: data.ContentType,
          content_id: data.ContentId,
          content_num: data.ContentNum,
          custom_message: data.CustomMessage,
          open_at: data.OpenAt,
          expired_at: data.ExpiredAt,
        },
      });
    },
    {
      onSuccess: (d: any) => {
        notify("success", { type: "success" });
        navigate("/AdminPresent");
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  const onSubmit = (data: any) => {
    mutate(data);
  };

  return (
    <Toolbar>
      <SaveButton label="作成" onClick={handleSubmit(onSubmit)} disabled={isLoading} />
    </Toolbar>
  );
};

export const AdminPresentCreate = (props: CreateProps) => {
  return (
    <Create {...props} redirect="list">
      <SimpleForm toolbar={<AdminPresentCreateToolbar />}>
        <ArrayInput source="UserIDs" label="ユーザーID" defaultValue={[""]} validate={[required(), minLength(1)]}>
          <SimpleFormIterator inline>
            {/*
              sourceの値を設定するとオブジェクトの配列として解釈されるため空文字で設定。
              また、要素追加時に余計な文字列が挿入されるのを防ぐためにformatを設定。
              https://github.com/marmelab/react-admin/issues/2383#issuecomment-593880920
            */}
            <TextInput source="" format={(value) => (typeof value === "object" ? "" : value)} sx={{ width: "350px" }} validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
        <ContentTypeWithUnifiedContentInput />
        <NumInput source="ContentNum" label="個数" validate={required()} />
        <TextInput source="CustomMessage" label="カスタムメッセージ" helperText="設定しない場合はデフォルトの値が利用されます" sx={{ width: "500px" }} />
        <DateTimeWithSecInput source="OpenAt" label="付与開始日時" helperText="プレゼントの作成日時になります" validate={required()} />
        <DateTimeWithSecInput source="ExpiredAt" label="受取期限" helperText="プレゼントの有効期限になります" validate={required()} />
      </SimpleForm>
    </Create>
  );
};
