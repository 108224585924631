import { Datagrid, NumberInput, TextField, TextInput, ListProps } from "react-admin";
import { CommonList, NumField, YMDHMSDateField } from "../Common";

const userStaminaFilters = [<NumberInput key="stamina" source="Stamina" label="AP" />, <TextInput key="lastUpdatedAt" source="LastUpdatedAt" label="最終更新日時" />];

export const UserStaminaList = (props: ListProps) => (
  <CommonList {...props} addFilters={userStaminaFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="Stamina" label="AP" />
      <YMDHMSDateField source="LastUpdatedAt" label="最終更新日時" />
    </Datagrid>
  </CommonList>
);
