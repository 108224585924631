import { Datagrid, TextField, NumberInput } from "react-admin";
import { CommonList, YMDHMSDateField, NumField } from "../Common";

const userLoginFilters = [<NumberInput key="loginDays" source="LoginDays" label="ログイン日数" />];

export const UserLoginList = (props: any) => (
  <CommonList {...props} addFilters={userLoginFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="LoginDays" label="ログイン日数" />
      <YMDHMSDateField source="LastLoginAt" label="最終ログイン日時" />
    </Datagrid>
  </CommonList>
);
