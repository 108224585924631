import { IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { useState } from "react";

export const WildcardHelpButton = ({ show = false }: { show?: boolean }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!show) return null;

  return (
    <>
      <IconButton onClick={handleClickOpen} size="small" style={{ padding: "2 0px" }}>
        <HelpIcon fontSize="small" />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>ワイルドカード検索について</DialogTitle>
        <DialogContent>
          <DialogContentText>
            フィルター項目に「Like」が付いている場合、ワイルドカード検索が使用できます。 例えば、「%keyword%」と入力すると、keywordを含むすべての結果が表示されます。
            <br />
            「_」は特定の1文字を表し、例えば「_eyword」は「keyword」や「1eyword」などを含むすべての結果が表示されます。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <span>閉じる</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WildcardHelpButton;
