import { useNavigate } from "react-router-dom";
import { Datagrid, NumberInput, TextField, TextInput, useRecordContext, FunctionField } from "react-admin";
import { CommonList, YMDHMSDateField, DateTimeWithSecInput, NumField } from "../Common";
import { Typography } from "@mui/material";
import { Platforms } from "../../common/Platform";
import { StoneTypes } from "../../common/StoneType";

const filters = [
  <TextInput key="id" source="Id" label="ID" />,
  <NumberInput key="stoneType" source="StoneType" label="ジュエル種別" />,
  <NumberInput key="platform" source="Platform" label="プラットフォーム" />,
  <NumberInput key="acquiredCount" source="AcquiredCount" label="獲得数" />,
  <NumberInput key="count" source="Count" label="現在の所持数" />,
  <DateTimeWithSecInput key="acquiredAtFrom" source="AcquiredAt_from" label="獲得日時From" />,
  <DateTimeWithSecInput key="acquiredAtTo" source="AcquiredAt_to" label="獲得日時To" />,
];

const UserStoneDetailIdField = (props: any) => {
  const record = useRecordContext();
  const navigate = useNavigate();

  const handleClick = () => {
    const filter = JSON.stringify({
      UserId: record["UserId"],
      UserStoneDetailId: record["Id"],
    });
    navigate(`/LogUserStone?filter=${encodeURIComponent(filter)}`);
  };

  return (
    <Typography
      component="span"
      onClick={handleClick}
      style={{
        cursor: "pointer",
        color: "blue",
        textDecoration: "underline",
      }}
    >
      <TextField source="Id" />
    </Typography>
  );
};

export const UserStoneDetailList = (props: any) => (
  <CommonList {...props} addFilters={filters}>
    <Datagrid bulkActionButtons={false}>
      <UserStoneDetailIdField source="Id" label="ID" />
      <TextField source="UserId" label="ユーザーID" />
      <FunctionField label="ジュエル種別" render={(r: any) => `${r.StoneType}: ${StoneTypes[r.StoneType]}`} />
      <FunctionField label="プラットフォーム" render={(r: any) => `${r.Platform}: ${Platforms[r.Platform]}`} />
      <NumField source="AcquiredCount" label="獲得数" />
      <NumField source="Count" label="現在の所持数" />
      <YMDHMSDateField source="AcquiredAt" label="獲得日時" />
    </Datagrid>
  </CommonList>
);
