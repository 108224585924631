import { BooleanField, BooleanInput, Datagrid, NumberInput, TextField } from "react-admin";
import { CommonList, NumField } from "../Common";

const userHomeCardFilters = [
  <NumberInput key="cardNumber" source="CardNumber" label="カードNO" />,
  <NumberInput key="cardType" source="CardType" label="カードタイプ" />,
  <NumberInput key="cardId" source="CardId" label="カードID" />,
  <BooleanInput key="useReleasedIllust" source="UseReleasedIllust" label="解放イラスト使用" />,
];

export const UserHomeCardList = (props: any) => (
  <CommonList {...props} addFilters={userHomeCardFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="CardNumber" label="カードNO" />
      <NumField source="CardType" label="カードタイプ" />
      <NumField source="CardId" label="カードID" />
      <BooleanField source="UseReleasedIllust" label="解放イラスト使用" />
    </Datagrid>
  </CommonList>
);
