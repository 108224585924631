import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField, YMDHMSDateField, DateTimeWithSecInput } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="questBattleDifficultyId" source="QuestBattleDifficultyId" label="クエストバトル難易度ID" />,
  <NumberInput key="enemyGroupId1" source="EnemyGroupId_1" label="敵グループID 1" />,
  <NumberInput key="enemyGroupId2" source="EnemyGroupId_2" label="敵グループID 2" />,
  <NumberInput key="enemyGroupId3" source="EnemyGroupId_3" label="敵グループID 3" />,
  <NumberInput key="partyNumber" source="PartyNumber" label="パーティ番号" />,
  <NumberInput key="totalPower" source="TotalPower" label="戦力" />,
  <DateTimeWithSecInput key="battleExpiredAtFrom" source="BattleExpiredAt_from" label="バトル期限From" />,
  <DateTimeWithSecInput key="battleExpiredAtAafter" source="BattleExpiredAt_after" label="バトル期限After" />,
]);

// TODO JSON field
export const LogUserBattleStartList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="QuestBattleDifficultyId" label="クエストバトル難易度ID" />
    <NumField source="EnemyGroupId_1" label="敵グループID 1" />
    <NumField source="EnemyGroupId_2" label="敵グループID 2" />
    <NumField source="EnemyGroupId_3" label="敵グループID 3" />
    <NumField source="PartyNumber" label="パーティ番号" />
    <NumField source="TotalPower" label="戦力" />
    <YMDHMSDateField source="BattleExpiredAt" label="バトル期限" />
  </CommonLogUserList>
);
