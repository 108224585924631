import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField, ReferenceCharacterCardField } from "../Common";

const filters = CommonLogUserFilter([<NumberInput key="characterCardId" source="CharacterCardId" label="カードID" />]);

export const LogUserCharacterCardList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="CharacterCardId" label="カードID" />
    <ReferenceCharacterCardField label="MCカード名" />
  </CommonLogUserList>
);
