import { useNavigate } from "react-router-dom";
import { useRecordContext, TextField } from "react-admin";
import { Typography } from "@mui/material";

export const LogUserActionIdField = (props: any) => {
  const record = useRecordContext();
  const navigate = useNavigate();

  const handleClick = () => {
    const filter = JSON.stringify({ Id: record[props.source] });
    navigate(`/LogUserAction?filter=${encodeURIComponent(filter)}`);
  };

  return (
    <Typography component="span" onClick={handleClick} style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}>
      <TextField {...props} />
    </Typography>
  );
};
