import * as React from "react";
import { Card, CardContent, CardHeader, Typography, Box, Grid } from "@mui/material";
import { Title } from "../../utils/title";
import dataProvider from "../../providers/dataProvider";
import { ToJST } from "../../utils/date";

export const Dashboard = () => {
  interface RequestTime {
    BaseTime: string;
    RequestTime: string;
    GameTime: string;
  }

  const [requestTime, setRequestTime] = React.useState<RequestTime | null>(null);

  React.useEffect(() => {
    if (process.env.REACT_APP_ENV !== "prod") {
      dataProvider(0)
        .debug("RequestTime/InspectGlobalRequestTime", "POST", "{}")
        .then((result) => {
          setRequestTime(result.data);
        });
    }
  }, []);

  return (
    <Card>
      <CardHeader title={Title()} />
      <CardContent>
        {process.env.REACT_APP_ENV !== "prod" && (
          <Card>
            <CardHeader
              title={
                <Box sx={{ backgroundColor: "#f5f5f5", padding: "8px" }}>
                  <Typography variant="h6">全体時間変更</Typography>
                </Box>
              }
            />
            <CardContent>
              {requestTime ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="textSecondary">
                      BaseTime (基準時間):
                    </Typography>
                    <Typography variant="h6">{ToJST(requestTime.BaseTime)}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="textSecondary">
                      RequestTime (変更した時間):
                    </Typography>
                    <Typography variant="h6">{ToJST(requestTime.RequestTime)}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="textSecondary">
                      GameTime (ゲーム内の時間):
                    </Typography>
                    <Typography variant="h6">{ToJST(requestTime.GameTime)}</Typography>
                  </Grid>
                </Grid>
              ) : (
                <Typography variant="body2">変更がありません。</Typography>
              )}
            </CardContent>
          </Card>
        )}
      </CardContent>
    </Card>
  );
};
