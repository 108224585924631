import { Datagrid, NumberInput, TextField, ListProps } from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField, NumField } from "../Common";

const userBattlePassPurchaseFilters = [
  <NumberInput key="battlePassSeasonId" source="BattlePassSeasonId" label="BattlePassSeasonId" />,
  <DateTimeWithSecInput key="purchasedAtFrom" source="PurchasedAt_from" label="購入日時From" />,
  <DateTimeWithSecInput key="purchasedAtTo" source="PurchasedAt_to" label="購入日時To" />,
];

export const UserBattlePassPurchaseList = (props: ListProps) => (
  <CommonList {...props} addFilters={userBattlePassPurchaseFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="BattlePassSeasonId" label="BattlePassSeasonId" />
      <YMDHMSDateField source="PurchasedAt" label="購入日時" />
    </Datagrid>
  </CommonList>
);
