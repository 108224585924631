import { Datagrid, TextField, NumberInput, ListProps } from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField, NumField } from "../Common";

const userEventLoginBonusFilters = [
  <NumberInput key="seasonId" source="SeasonId" label="SeasonId" />,
  <NumberInput key="receivedCount" source="ReceivedCount" label="受取回数" />,
  <DateTimeWithSecInput key="lastReceiveAtFrom" source="LastReceiveAt_from" label="報酬受取日時From" />,
  <DateTimeWithSecInput key="lastReceiveAtTo" source="LastReceiveAt_to" label="報酬受取日時To" />,
];

export const UserEventLoginBonusList = (props: ListProps) => (
  <CommonList {...props} addFilters={userEventLoginBonusFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="SeasonId" label="SeasonId" />
      <NumField source="ReceivedCount" label="受取回数" />
      <YMDHMSDateField source="LastReceiveAt" label="最終受取日時" />
    </Datagrid>
  </CommonList>
);
