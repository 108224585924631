import React, { useState } from "react";
import { useDataProvider, TextInput, SimpleForm, SaveButton, Toolbar, useNotify, Button, Create } from "react-admin";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import RestoreIcon from "@mui/icons-material/Restore";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { ToJST } from "utils/date";
import { Platforms } from "../../common/Platform";

interface UserAccount {
  FirebaseUid: string;
  UserId: string;
  DeviceSessionId: string;
  Platform: number;
}

interface UserProfile {
  Name: string;
  Comment: string;
  HonorId: string;
  CardType: string;
  CardId: string;
}

interface UserLogin {
  LoginDays: number;
  LastLoginAt: string;
}

interface User {
  CreatedAt: string;
}

interface AccountDetails {
  UserAccount: UserAccount;
  UserProfile: UserProfile;
  UserLogin: UserLogin;
  User: User;
}

interface RecoverAccountDetails {
  NewUser: AccountDetails;
  RecoverUser: AccountDetails;
}

export const RecoverAccount: React.FC = () => {
  const [newUserId, setNewUserId] = useState("");
  const [recoverUserId, setRecoverUserId] = useState("");
  const [accountDetails, setAccountDetails] = useState<RecoverAccountDetails | null>(null);
  const [showDialog, setShowDialog] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const navigate = useNavigate();

  const { mutate: handleConfirm, isLoading: isConfirmLoading } = useMutation(
    async () => {
      const response = await dataProvider.getList("RecoverAccount", {
        filter: { newUserId, recoverUserId },
        pagination: { page: 1, perPage: 1 },
        sort: { field: "id", order: "ASC" },
      });
      return response.data[0];
    },
    {
      onSuccess: (data) => {
        setAccountDetails(data);
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
        setAccountDetails(null);
      },
    }
  );

  const { mutate: handleRecover, isLoading: isRecoverLoading } = useMutation(
    async () => {
      await dataProvider.create("RecoverAccount", {
        data: { id: "", newUserId, recoverUserId },
      });
    },
    {
      onSuccess: () => {
        setShowDialog(true);
        setConfirmDialogOpen(false);
        setAccountDetails(null);
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
        setConfirmDialogOpen(false);
        setAccountDetails(null);
      },
    }
  );

  const handleRecoverClick = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleLogButtonClick = () => {
    navigate("/LogUserAccountRecoveryAdminAction");
  };

  const renderUserDetailsTable = (details: RecoverAccountDetails) => (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>項目</TableCell>
            <TableCell>復旧したいユーザー</TableCell>
            <TableCell>新規作成したユーザー</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>ユーザーID</TableCell>
            <TableCell>{details.RecoverUser.UserAccount.UserId}</TableCell>
            <TableCell>{details.NewUser.UserAccount.UserId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>FirebaseUid</TableCell>
            <TableCell>{details.RecoverUser.UserAccount.FirebaseUid}</TableCell>
            <TableCell>{details.NewUser.UserAccount.FirebaseUid}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>名前</TableCell>
            <TableCell>{details.RecoverUser.UserProfile.Name}</TableCell>
            <TableCell>{details.NewUser.UserProfile.Name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>プラットフォーム</TableCell>
            <TableCell>{Platforms[details.RecoverUser.UserAccount.Platform]}</TableCell>
            <TableCell>{Platforms[details.NewUser.UserAccount.Platform]}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ユーザー作成日</TableCell>
            <TableCell>{ToJST(details.RecoverUser.User.CreatedAt)}</TableCell>
            <TableCell>{ToJST(details.NewUser.User.CreatedAt)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>最終ログイン日時</TableCell>
            <TableCell>{ToJST(details.RecoverUser.UserLogin.LastLoginAt)}</TableCell>
            <TableCell>{ToJST(details.NewUser.UserLogin.LastLoginAt)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>コメント</TableCell>
            <TableCell>{details.RecoverUser.UserProfile.Comment}</TableCell>
            <TableCell>{details.NewUser.UserProfile.Comment}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Create title="アカウント復旧">
      <Typography variant="body1" paragraph sx={{ margin: 1 }}>
        アカウントを復旧するための機能です。
        <br />
        復旧したいユーザーIDと、復旧の踏み台として新規に作成してもらったユーザーIDを入力してください。
        <br />
        復旧後は復旧対象ユーザーのFirebaseUidが新規作成のFirebaseUidに変更されます。
        <br />
        復旧完了後は新規作成ユーザーは無効になります。<></>
      </Typography>
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton label="確認" onClick={() => handleConfirm()} disabled={isConfirmLoading} icon={<CheckIcon />} />
          </Toolbar>
        }
      >
        <TextInput
          source="recoverUserId"
          label="復旧したいユーザーID"
          value={recoverUserId}
          onChange={(e) => setRecoverUserId(e.target.value)}
          required
          sx={{ width: "350px", marginBottom: 2 }}
        />
        <TextInput
          source="UserId"
          label="新規作成したユーザーID"
          value={newUserId}
          onChange={(e) => setNewUserId(e.target.value)}
          required
          sx={{ width: "350px", marginBottom: 2 }}
        />
      </SimpleForm>
      {accountDetails && (
        <>
          {renderUserDetailsTable(accountDetails)}
          <Toolbar>
            <Box display="flex" gap={2} sx={{ marginTop: 2 }}>
              <Button label="復旧" onClick={handleRecoverClick} variant="contained" startIcon={<RestoreIcon />} sx={{ height: "36px" }} disabled={isRecoverLoading} />
              <Button label="キャンセル" onClick={() => setAccountDetails(null)} variant="contained" startIcon={<CancelIcon />} sx={{ height: "36px" }} />
            </Box>
          </Toolbar>
        </>
      )}
      <Dialog open={showDialog} onClose={handleCloseDialog}>
        <DialogTitle>完了</DialogTitle>
        <DialogContent>
          <DialogContentText>アカウントが正常に復旧されました。</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" variant="contained" label="閉じる" />
          <Button onClick={handleLogButtonClick} color="primary" variant="contained" label="ログを確認" />
        </DialogActions>
      </Dialog>
      <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
        <DialogTitle>確認</DialogTitle>
        <DialogContent>
          <DialogContentText>復旧します。よろしいですか？</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleRecover()} color="primary" autoFocus label="復旧" variant="contained" />
          <Button onClick={handleConfirmDialogClose} color="primary" label="キャンセル" variant="contained" />
        </DialogActions>
      </Dialog>
    </Create>
  );
};

export default RecoverAccount;
