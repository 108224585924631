import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField, ReferenceMissionField } from "../Common";

const filters = CommonLogUserFilter([<NumberInput key="missionId" source="MissionId" label="ミッションID" />]);

export const LogUserMissionRewardList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="MissionId" label="ミッションID" />
    <ReferenceMissionField label="ミッション名" />
  </CommonLogUserList>
);
