import { Datagrid, TextField, NumberInput, BooleanField, BooleanInput, ReferenceField } from "react-admin";
import { CommonList, NumField } from "../Common";

const userQuestBattleDifficultyFilters = [
  <NumberInput key="questBattleDifficultyId" source="QuestBattleDifficultyId" label="QuestBattleDifficultyId" />,
  <BooleanInput key="isPlayed" source="IsPlayed" label="プレイ済み" />,
  <BooleanInput key="isCleared" source="IsCleared" label="クリア済み" />,
];

export const UserQuestBattleDifficultyList = (props: any) => (
  <CommonList {...props} addFilters={userQuestBattleDifficultyFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="QuestBattleDifficultyId" label="QuestBattleDifficultyId" />
      <ReferenceField source="QuestBattleDifficultyId" reference="QuestBattleDifficulty" label="クエスト名">
        <TextField source="title" />
      </ReferenceField>
      <BooleanField source="IsPlayed" label="プレイ済み" />
      <BooleanField source="IsCleared" label="クリア済み" />
    </Datagrid>
  </CommonList>
);
