import { Table, TableHead, TableRow, TableBody, TableCell, Box } from "@mui/material";
import { SimulatorResult } from ".";

type GachaResultListViewProps = {
  contents: SimulatorResult;
};

export const GachaResultListView = ({ contents }: GachaResultListViewProps) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>出現数</TableCell>
          <TableCell>実値(%)</TableCell>
          <TableCell>
            確立表記(%)
            <Box fontWeight="fontWeightLight" fontSize={12}>
              ※マスターから取得
            </Box>
          </TableCell>
          <TableCell>カード種別</TableCell>
          <TableCell>カードID</TableCell>
          <TableCell>ピックアップ</TableCell>
          <TableCell>確定枠</TableCell>
        </TableRow>
      </TableHead>
      {contents.contents.map((content) => {
        const totalWeight = content.is_last ? contents.total_weight_last : contents.total_weight;
        const drawCount = content.is_last ? contents.total_count_last : contents.total_count;
        const cardType = content.card_type === 1 ? "MCカード" : "メモリーカード";

        return (
          <TableBody key={content.id}>
            <TableRow>
              <TableCell>{content.count}</TableCell>
              <TableCell>{((content.count / drawCount) * 100).toFixed(5)}</TableCell>
              <TableCell>{((content.weight / totalWeight) * 100).toFixed(5)}</TableCell>
              <TableCell>{cardType}</TableCell>
              <TableCell>{content.card_id}</TableCell>
              <TableCell>{content.is_pickup.toString()}</TableCell>
              <TableCell>{content.is_last.toString()}</TableCell>
            </TableRow>
          </TableBody>
        );
      })}
    </Table>
  );
};
