import { Datagrid, TextField, NumberInput, ReferenceField, ListProps } from "react-admin";
import { CommonList, NumField } from "../Common";

const userHonorMissionFilters = [
  <NumberInput key="honorMissionId" source="HonorMissionId" label="HonorMissionId" />,
  <NumberInput key="progress" source="Progress" label="進捗" />,
];

export const UserHonorMissionList = (props: ListProps) => (
  <CommonList {...props} addFilters={userHonorMissionFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="HonorMissionId" label="HonorMissionId" />
      <ReferenceField source="HonorMissionId" reference="HonorMission" label="Value">
        <TextField source="value" />
      </ReferenceField>
      <NumField source="Progress" label="進捗" />
    </Datagrid>
  </CommonList>
);
