import React from "react";
import { Datagrid, TextField, TextInput, ListProps, BooleanField, BooleanInput } from "react-admin";
import { CommonList, YMDHMSDateField, DateTimeWithSecInput } from "../Common";

const filters = [
  <TextInput key="Id" source="Id" label="ID" sx={{ width: "350px" }} />,
  <TextInput key="UserId" source="UserId" label="ユーザーID" sx={{ width: "350px" }} />,
  <TextInput key="OldFirebaseUid" source="OldFirebaseUid" label="旧FirebaseUID" sx={{ width: "350px" }} />,
  <TextInput key="NewFirebaseUid" source="NewFirebaseUid" label="新FirebaseUID" sx={{ width: "350px" }} />,
  <BooleanInput key="IsCompensated" source="IsCompensated" label="補填済" />,
  <DateTimeWithSecInput key="CreatedAt_from" source="CreatedAt_from" label="作成日時From" />,
  <DateTimeWithSecInput key="CreatedAt_to" source="CreatedAt_to" label="作成日時To" />,
];

export const LogUserAccountRecoveryList = (props: ListProps) => (
  <>
    <p>バグによりアカウント連携が失敗して、クライアント側とサーバー側のFirebaseUidがミスマッチしてしまう状況を自動で修正したログ</p>
    <CommonList {...props} sort={{ field: "CreatedAt", order: "DESC" }} filters={filters}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="Id" label="ID" />
        <TextField source="UserId" label="ユーザーID" />
        <TextField source="OldFirebaseUid" label="旧FirebaseUID" />
        <TextField source="NewFirebaseUid" label="新FirebaseUID" />
        <BooleanField source="IsCompensated" label="補填済" />
        <YMDHMSDateField source="CreatedAt" label="作成日時" />
      </Datagrid>
    </CommonList>
  </>
);
