import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField, ReferenceEquipmentCardField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="equipmentCardId" source="EquipmentCardId" label="メモリーカードID" />,
  <NumberInput key="equipmentCardNumber" source="EquipmentCardNumber" label="メモリーカード番号" />,
  <NumberInput key="exp" source="Exp" label="Exp" />,
  <NumberInput key="beforeExp" source="BeforeExp" label="ExpBefore" />,
  <NumberInput key="afterExp" source="AfterExp" label="ExpAfter" />,
]);

export const LogUserEquipmentCardExpList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="EquipmentCardId" label="メモリーカードID" />
    <ReferenceEquipmentCardField label="メモリーカード名" />
    <NumField source="EquipmentCardNumber" label="メモリーカード番号" />
    <NumField source="Exp" label="Exp" />
    <NumField source="BeforeExp" label="ExpBefore" />
    <NumField source="AfterExp" label="ExpAfter" />
  </CommonLogUserList>
);
