import { Datagrid, TextField, NumberInput } from "react-admin";
import { CommonList, NumField } from "../Common";

const userActCharacterFilters = [
  <NumberInput key="slotNumber" source="SlotNumber" label="スロットNO" />,
  <NumberInput key="characterType" source="CharacterType" label="CharacterType" />,
];

export const UserActCharacterList = (props: any) => (
  <CommonList {...props} addFilters={userActCharacterFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="SlotNumber" label="スロットNO" />
      <NumField source="CharacterType" label="CharacterType" />
    </Datagrid>
  </CommonList>
);
