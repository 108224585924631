import { CheckboxGroupInput, Create, Datagrid, DateTimeInput, Edit, EditButton, FunctionField, List, SimpleForm, maxLength, useRecordContext, SelectInput } from "react-admin";
import { DateTimeWithSecInput, EditTitle, EditToolbar, TextWithBreaksField, TextWithCharCountInput, YMDHMSDateField, CommonActions } from "../Common";
import { CurrentDate, FormatDate } from "../../utils/date";
import { Chip } from "@mui/material";
import { Platforms } from "../../config/platform";

const messageMaxLength = 255;

const maintenanceTypeChoices = [
  { id: 1, label: "通常" },
  { id: 2, label: "サービス開始前" },
];

const transformMaintenanceEdit = (data: any) => {
  return {
    ...data,
    Platforms: data.Platforms.map((value: any) => parseInt(value, 10)),
  };
};

const renderPlatforms = (record: any) => {
  if (!record || !record.Platforms) {
    return null;
  }

  return (
    <div>
      {record.Platforms.map((id: number) => {
        const choice = Platforms.find((choice) => choice.id === id);
        return choice ? <Chip key={id} label={choice.label} style={{ margin: 2 }} /> : id;
      })}
    </div>
  );
};

const renderMaintenanceType = (record: any) => {
  const choice = maintenanceTypeChoices.find((choice) => choice.id === record.Type);
  return choice ? choice.label : record.Type;
};

const MaintenanceStatusField = (props: any) => {
  const record = useRecordContext();

  const currentDate = CurrentDate();
  const isOpen = currentDate >= record?.OpenAt && currentDate <= record?.CloseAt;
  return <div style={{ color: isOpen ? "red" : "green" }}>{isOpen ? "メンテナンス中" : "メンテナンス外"}</div>;
};

export const MaintenanceList = (props: any) => (
  <List {...props} filters={[]} actions={<CommonActions />} pagination={null} sort={{ field: "CreatedAt", order: "DESC" }} empty={false}>
    <Datagrid bulkActionButtons={false}>
      <YMDHMSDateField label="作成日時" source="CreatedAt" />
      <FunctionField label="メンテナンスタイプ" render={renderMaintenanceType} />
      <FunctionField label="プラットフォーム" render={renderPlatforms} />
      <YMDHMSDateField label="開始日時" source="OpenAt" />
      <YMDHMSDateField label="終了日時" source="CloseAt" />
      <TextWithBreaksField label="メッセージ" source="Message" sortable={false} />
      <MaintenanceStatusField label="状態" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);

const validateMaintenanceEdit = (values: any) => {
  const errors = {} as any;

  if (!values.Type) {
    errors.Type = "タイプを選択してください";
  }

  if (!values.Platforms || values.Platforms.length === 0) {
    errors.Platforms = "プラットフォームを選択してください";
  }

  const openAt = FormatDate(values.OpenAt);
  const closeAt = FormatDate(values.CloseAt);

  if (openAt >= closeAt) {
    errors.OpenAt = "OpenAtはCloseAtより前でなければなりません";
    errors.CloseAt = "CloseAtはOpenAtより後でなければなりません";
  }

  if (maxLength(messageMaxLength)(values.Message, values)) {
    errors.Message = `Messageは${messageMaxLength}文字以下でなければなりません`;
  }
  return errors;
};

export const MaintenanceEdit = (props: any) => (
  <Edit {...props} title={<EditTitle name="メンテナンス" />}>
    <SimpleForm validate={validateMaintenanceEdit} toolbar={<EditToolbar />}>
      <DateTimeInput label="作成日時" source="CreatedAt" readOnly />
      <SelectInput label="メンテナンスタイプ" source="Type" choices={maintenanceTypeChoices} optionText="label" />
      <CheckboxGroupInput label="プラットフォーム" source="Platforms" choices={Platforms} optionText="label" />
      <DateTimeWithSecInput label="開始日時" source="OpenAt" required />
      <DateTimeWithSecInput label="終了日時" source="CloseAt" required />
      <TextWithCharCountInput label="メッセージ" source="Message" multiline rows="10" maxLength={messageMaxLength} sx={{ width: 500 }} />
    </SimpleForm>
  </Edit>
);

export const MaintenanceCreate = (props: any) => (
  <Create {...props} redirect="list" transform={transformMaintenanceEdit}>
    <SimpleForm validate={validateMaintenanceEdit}>
      <SelectInput label="メンテナンスタイプ" source="Type" choices={maintenanceTypeChoices} optionText="label" />
      <CheckboxGroupInput label="プラットフォーム" source="Platforms" choices={Platforms} defaultValue={["1", "2"]} optionText="label" />
      <DateTimeWithSecInput label="開始日時" source="OpenAt" required />
      <DateTimeWithSecInput label="終了日時" source="CloseAt" required />
      <TextWithCharCountInput label="メッセージ" source="Message" multiline rows="10" maxLength={messageMaxLength} sx={{ width: 500 }} />
    </SimpleForm>
  </Create>
);
