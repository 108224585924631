import React, { useState } from "react";
import { BooleanField, Datagrid, NumberInput, TextField, BooleanInput, ReferenceField, Show, SimpleShowLayout, ShowButton, FunctionField } from "react-admin";
import { CommonList, DateTimeWithSecInput, NumField, YMDHMSDateField, ReferenceCharacterCardField } from "../Common";
import { GetMasterData } from "../../common/MasterData";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const userCharacterCardFilters = [
  <NumberInput key="characterCardId" source="CharacterCardId" label="CharacterCardId" />,
  <NumberInput key="exp" source="Exp" label="EXP" />,
  <BooleanInput key="isFavorite" source="IsFavorite" label="お気に入り" />,
  <BooleanInput key="isChangeImage" source="IsChangeImage" label="イラスト変更" />,
  <DateTimeWithSecInput key="acquiredAtFrom" source="AcquiredAt_from" label="獲得日時From" />,
  <DateTimeWithSecInput key="acquiredAtTo" source="AcquiredAt_to" label="獲得日時To" />,
];

export const UserCharacterCardList = (props: any) => (
  <CommonList {...props} addFilters={userCharacterCardFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="CharacterCardId" label="CharacterCardId" />
      <ReferenceCharacterCardField label="MCカード名" />
      <NumField source="Exp" label="EXP" />
      <BooleanField source="IsFavorite" label="お気に入り" />
      <BooleanField source="IsChangeImage" label="イラスト変更" />
      <YMDHMSDateField source="AcquiredAt" label="獲得日時" />
      <ShowButton />
    </Datagrid>
  </CommonList>
);

export const UserCharacterCardShow = (props: any) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // 前のページに戻る
  };

  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

  const handleToggle = (id: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <Show {...props} resource="UserCharacterCardDetail">
      <SimpleShowLayout divider={<Divider />}>
        <NumField source="CalculatedCharacterCard.CharacterCardId" label="CharacterCardId" />
        <ReferenceField source="CalculatedCharacterCard.CharacterCardId" reference="CharacterCard" label="カード名">
          <TextField source="card_name" />
        </ReferenceField>
        <FunctionField label="レアリティ" render={(record: any) => RarityTypeString(record.CalculatedCharacterCard.Rarity)} />
        <FunctionField label="覚醒段階" render={(record: any) => record.CalculatedCharacterCard.Grade + 1} />
        <NumField source="CalculatedCharacterCard.Level" label="レベル" />
        <NumField source="CalculatedCharacterCard.MaxLevel" label="最大レベル" />
        <NumField source="CalculatedCharacterCard.Exp" label="EXP" />
        <NumField source="CalculatedCharacterCard.HP" label="HP" />
        <NumField source="CalculatedCharacterCard.ATK" label="ATH" />
        <NumField source="CalculatedCharacterCard.DEF" label="DEF" />
        <FunctionField
          label="スキル情報"
          render={(record: any) => {
            const isExpanded = expandedRows["skill"] || false;

            return (
              <div>
                <Button variant="outlined" color="primary" onClick={() => handleToggle("skill")} endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                  {isExpanded ? "閉じる" : "表示"}
                </Button>
                {isExpanded && <CharacterCardSkillField data={record.CalculatedCharacterCard.CharacterCardSkills} />}
              </div>
            );
          }}
        />
        <FunctionField
          label="パネル情報"
          render={(record: any) => {
            const isExpanded = expandedRows["panel"] || false;

            return (
              <div>
                <Button variant="outlined" color="primary" onClick={() => handleToggle("panel")} endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                  {isExpanded ? "閉じる" : "表示"}
                </Button>
                {isExpanded && <CharacterCardPanelField data={record.CalculatedPanelBoards} />}
              </div>
            );
          }}
        />
      </SimpleShowLayout>
      <Button onClick={handleBackClick}>戻る</Button>
    </Show>
  );
};

function RarityTypeString(rarityType: number) {
  switch (rarityType) {
    case 0:
      return "N";
    case 1:
      return "R";
    case 2:
      return "SR";
    case 3:
      return "SSR";
    case 4:
      return "HR";
    default:
      return "未定義";
  }
}

const CharacterCardSkillField = (props: any) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "150px" }}>CharacterCardSkillGroup.ID</TableCell>
            <TableCell sx={{ width: "150px" }}>スキルタイプ</TableCell>
            <TableCell sx={{ width: "150px" }} align="right">
              スキルID
            </TableCell>
            <TableCell sx={{ width: "200px" }}>スキル名</TableCell>
            <TableCell sx={{ width: "150px" }} align="right">
              スキルレベル
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data?.map((CalculatedCharacterCardSkill: any) => {
            const skillMasterTypeString = SkillMasterTypeString(CalculatedCharacterCardSkill.skill_master_type);
            const skill = GetMasterData(skillMasterTypeString, CalculatedCharacterCardSkill.skill_id);
            return (
              <TableRow key={CalculatedCharacterCardSkill.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>{CalculatedCharacterCardSkill.id}</TableCell>
                <TableCell>{skillMasterTypeString}</TableCell>
                <TableCell align="right">{CalculatedCharacterCardSkill.skill_id}</TableCell>
                <TableCell>{skill?.name}</TableCell>
                <TableCell align="right"> {CalculatedCharacterCardSkill.SkillLevel} </TableCell>
                <TableCell />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function SkillMasterTypeString(skillMasterType: number) {
  switch (skillMasterType) {
    case 1:
      return "ActiveSkill";
    case 2:
      return "PassiveSkill";
    case 3:
      return "SpecialSkill";
    case 4:
      return "HypnosisAbility";
    case 5:
      return "NormalSkill";
    default:
      return "";
  }
}

const CharacterCardPanelField = (props: any) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "100px" }} />
            <TableCell sx={{ width: "150px" }}>パネルボードID</TableCell>
            <TableCell sx={{ width: "150px" }}>ボードタイプ</TableCell>
            <TableCell sx={{ width: "100px" }} align="right">
              段階
            </TableCell>
            <TableCell sx={{ width: "100px" }}>開放済</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data?.map((CalculatedPanelBoard: any) => {
            return <PanelGroupRow key={CalculatedPanelBoard.ID} row={CalculatedPanelBoard} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PanelGroupRow = (props: { row: any }) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const panelBoard = GetMasterData("PanelBoard", row.ID);

  return (
    <React.Fragment>
      <TableRow key={row.ID} sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.ID}
        </TableCell>
        <TableCell>{PanelBoardTypeString(panelBoard?.type)}</TableCell>
        <TableCell align="right">{panelBoard?.phase}</TableCell>
        <TableCell>
          <BooleanField source="IsReleased" record={row} />
        </TableCell>
        <TableCell />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="panels">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "150px" }}>パネルID</TableCell>
                    <TableCell sx={{ width: "150px" }}>パラメータタイプ</TableCell>
                    <TableCell sx={{ width: "150px" }} align="right">
                      パラメータ値
                    </TableCell>
                    <TableCell sx={{ width: "100px" }}>開放済</TableCell>
                    <TableCell sx={{ width: "100px" }}>強化済</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.Panels?.map((calculatedPanel: any) => {
                    const panel = GetMasterData("Panel", calculatedPanel.ID);
                    return (
                      <TableRow
                        key={calculatedPanel.ID}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{calculatedPanel.ID}</TableCell>
                        <TableCell>{PanelParamTypeString(panel?.param_type)}</TableCell>
                        <TableCell align="right">{panel?.param_value}</TableCell>
                        <TableCell>
                          <BooleanField source="IsReleased" record={calculatedPanel} />
                        </TableCell>
                        <TableCell>
                          <BooleanField source="IsEnhanced" record={calculatedPanel} />
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

function PanelBoardTypeString(panelBoardType: number) {
  switch (panelBoardType) {
    case 0:
      return "ランク";
    case 1:
      return "覚醒";
    default:
      return "未定義";
  }
}

function PanelParamTypeString(panelParamType: number) {
  switch (panelParamType) {
    case 0:
      return "Hp";
    case 1:
      return "Atk";
    case 2:
      return "HpRate";
    case 3:
      return "AtkRate";
    case 4:
      return "ActiveSkill1";
    case 5:
      return "ActiveSkill2";
    case 6:
      return "ActiveSkill3";
    case 7:
      return "PassiveSkill1";
    case 8:
      return "PassiveSkill2";
    case 9:
      return "PassiveSkill3";
    case 10:
      return "SpecialSkill";
    case 11:
      return "Grade";
    case 12:
      return "LevelLimit";
    case 13:
      return "Def";
    case 21:
      return "DefRate";
    default:
      return "未定義";
  }
}
