import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="battlePassSeasonId" source="BattlePassSeasonId" label="バトルパスシーズンID" />,
  <NumberInput key="exp" source="Exp" label="Exp" />,
  <NumberInput key="beforeExp" source="BeforeExp" label="ExpBefore" />,
  <NumberInput key="afterExp" source="AfterExp" label="ExpAfter" />,
  <NumberInput key="upLevel" source="UpLevel" label="レベルアップ数" />,
  <NumberInput key="beforeLevel" source="BeforeLevel" label="レベルBefore" />,
  <NumberInput key="afterLevel" source="AfterLevel" label="レベルAfter" />,
]);

export const LogUserBattlePassExpList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="BattlePassSeasonId" label="バトルパスシーズンID" />
    <NumField source="Exp" label="Exp" />
    <NumField source="BeforeExp" label="ExpBefore" />
    <NumField source="AfterExp" label="ExpAfter" />
    <NumField source="UpLevel" label="レベルアップ数" />
    <NumField source="BeforeLevel" label="レベルBefore" />
    <NumField source="AfterLevel" label="レベルAfter" />
  </CommonLogUserList>
);
