import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="gachaId" source="GachaId" label="ガチャID" />,
  <NumberInput key="count" source="Count" label="回数" />,
  <NumberInput key="purchaseCount" source="PurchaseCount" label="購入回数" />,
  <NumberInput key="totalPurchaseCount" source="TotalPurchaseCount" label="総購入回数" />,
]);

export const LogUserGachaList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="GachaId" label="ガチャID" />
    <NumField source="Count" label="回数" />
    <NumField source="PurchaseCount" label="購入回数" />
    <NumField source="TotalPurchaseCount" label="総購入回数" />
  </CommonLogUserList>
);
