import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField, ReferenceCharacterCardField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="characterCardId" source="CharacterCardId" label="MCカードID" />,
  <NumberInput key="panelId" source="PanelId" label="パネルID" />,
]);

export const LogUserCharacterPanelList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="CharacterCardId" label="MCカードID" />
    <ReferenceCharacterCardField label="MCカード名" />
    <NumField source="PanelId" label="パネルID" />
  </CommonLogUserList>
);
