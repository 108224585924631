import { Datagrid, NumberInput, TextField, SelectField, SelectInput, BooleanInput } from "react-admin";
import { CommonList, YMDHMSDateField, NumField, ContentReferenceField } from "../Common";
import { ContentTypes } from "common/ContentType";

const userPresentFilters = [
  <SelectInput key="contentType" source="ContentType" label="コンテンツタイプ" choices={ContentTypes} />,
  <NumberInput key="contentId" source="ContentId" label="コンテンツID" />,
  <BooleanInput key="isAvailable" source="IsAvailable" label="受取期限内" defaultValue={true} />,
  <BooleanInput key="isReceivable" source="IsReceivable" label="未受取" defaultValue={true} />,
];

export const UserPresentList = (props: any) => (
  <CommonList {...props} addFilters={userPresentFilters} filterDefaultValues={{ IsAvailable: "on" }}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="PresentId" label="プレゼントID" />
      <TextField source="UserId" label="ユーザーID" />
      <SelectField source="ContentType" label="コンテンツタイプ" choices={ContentTypes} />
      <NumField source="ContentId" label="コンテンツID" />
      <ContentReferenceField label="コンテンツ詳細" />
      <NumField source="ContentNum" label="個数" />
      <TextField source="CustomMessage" label="カスタムメッセージ" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
      <YMDHMSDateField source="ExpiredAt" label="受取期限" />
      <YMDHMSDateField source="ReceivedAt" label="受取日時" />
    </Datagrid>
  </CommonList>
);
