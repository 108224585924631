import * as React from "react";
import { DeleteWithConfirmButton, Toolbar, SaveButton, useRecordContext } from "react-admin";

export const EditToolbar = (props: any) => {
  const record = useRecordContext();
  return (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" translateOptions={{ name: record.id }} />
    </Toolbar>
  );
};

export const DeleteOnlyToolbar = (props: any) => {
  const record = useRecordContext();
  return (
    <Toolbar {...props}>
      <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" translateOptions={{ name: record.id }} />
    </Toolbar>
  );
};
