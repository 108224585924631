import { Datagrid, NumberInput, TextField, TextInput, ListProps } from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField, NumField } from "../Common";

const userActPassPurchaseFilters = [
  <TextInput key="id" source="Id" label="ID" />,
  <NumberInput key="actPassId" source="ActPassId" label="ActPassId" />,
  <NumberInput key="actPassGroupId" source="ActPassGroupId" label="ActPassGroupId" />,
  <DateTimeWithSecInput key="purchasedAtFrom" source="PurchasedAt_from" label="購入日時From" />,
  <DateTimeWithSecInput key="purchasedAtTo" source="PurchasedAt_to" label="購入日時To" />,
  <DateTimeWithSecInput key="openAtFrom" source="OpenAt_from" label="開始日時From" />,
  <DateTimeWithSecInput key="openAtTo" source="OpenAt_to" label="開始日時To" />,
  <DateTimeWithSecInput key="expiredAtFrom" source="ExpiredAt_from" label="期限From" />,
  <DateTimeWithSecInput key="expiredAtTo" source="ExpiredAt_to" label="期限To" />,
  <DateTimeWithSecInput key="lastReceiveAtFrom" source="LastReceiveAt_from" label="最終受取日時From" />,
  <DateTimeWithSecInput key="lastReceiveAtTo" source="LastReceiveAt_to" label="最終受取日時To" />,
];

export const UserActPassPurchaseList = (props: ListProps) => (
  <CommonList {...props} addFilters={userActPassPurchaseFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="Id" label="ID" />
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="ActPassId" label="ActPassId" />
      <NumField source="ActPassGroupId" label="ActPassGroupId" />
      <YMDHMSDateField source="PurchasedAt" label="購入日時" />
      <YMDHMSDateField source="OpenAt" label="開始日時" />
      <YMDHMSDateField source="ExpiredAt" label="期限" />
      <YMDHMSDateField source="LastReceiveAt" label="最終受取日時" />
    </Datagrid>
  </CommonList>
);
