import { NumberInput, TextField, TextInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField, ContentReferenceField } from "../Common";

const filters = CommonLogUserFilter([
  <TextInput key="presentId" source="PresentId" label="プレゼントID" />,
  <NumberInput key="contentType" source="ContentType" label="コンテンツタイプ" />,
  <NumberInput key="contentId" source="ContentId" label="コンテンツID" />,
  <NumberInput key="contentNum" source="ContentNum" label="コンテンツ数" />,
]);

export const LogUserPresentAcquisitionList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <TextField source="PresentId" label="プレゼントID" />
    <NumField source="ContentType" label="コンテンツタイプ" />
    <NumField source="ContentId" label="コンテンツID" />
    <ContentReferenceField label="コンテンツ詳細" />
    <NumField source="ContentNum" label="コンテンツ数" />
  </CommonLogUserList>
);
