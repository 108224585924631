import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([<NumberInput key="gachaStampId" source="GachaStampId" label="ガチャスタンプID" />, <NumberInput key="step" source="Step" label="ステップ" />]);

export const LogUserGachaStampList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="GachaStampId" label="ガチャスタンプID" />
    <NumField source="Step" label="ステップ" />
  </CommonLogUserList>
);
