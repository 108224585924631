import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="towerStageId" source="TowerStageId" label="タワーステージID" />,
  <NumberInput key="continueCount" source="ContinueCount" label="コンティニュー数" />,
  <NumberInput key="beforeContinueCount" source="BeforeContinueCount" label="コンティニュー数Before" />,
  <NumberInput key="afterContinueCount" source="AfterContinueCount" label="コンティニュー数After" />,
]);

export const LogUserTowerBattleContinueList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="TowerStageId" label="タワーステージID" />
    <NumField source="ContinueCount" label="コンティニュー数" />
    <NumField source="BeforeContinueCount" label="コンティニュー数Before" />
    <NumField source="AfterContinueCount" label="コンティニュー数After" />
  </CommonLogUserList>
);
