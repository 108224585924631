import { Datagrid, TextField, TextInput, Edit, EditButton, Create, SimpleForm } from "react-admin";
import { CommonList, DeleteOnlyToolbar, EditTitle } from "../Common";

export const UserMaintenanceList = (props: any) => (
  <CommonList {...props} filters={null}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="UserId" />
      <EditButton />
    </Datagrid>
  </CommonList>
);

export const UserMaintenanceCreate = (props: any) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <TextInput source="UserId" label="UserId" fullWidth />
    </SimpleForm>
  </Create>
);

export const UserMaintenanceEdit = (props: any) => (
  <Edit {...props} title={<EditTitle name="UserMaintenance" />}>
    <SimpleForm toolbar={<DeleteOnlyToolbar />}>
      <TextInput disabled source="UserId" fullWidth />
    </SimpleForm>
  </Edit>
);
