import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="boxGachaId" source="BoxGachaId" label="ボックスガチャID" />,
  <NumberInput key="execNum" source="ExecNum" label="実行回数" />,
  <NumberInput key="step" source="Step" label="ステップ" />,
]);

export const LogUserBoxGachaExecList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="BoxGachaId" label="ボックスガチャID" />
    <NumField source="ExecNum" label="実行回数" />
    <NumField source="Step" label="ステップ" />
  </CommonLogUserList>
);
