export const ContentType = {
  CHARACTER_CARD: 1,
  EQUIPMENT_CARD: 2,
  ITEM: 3,
  MONEY: 4,
  STONE: 5,
  BILLED_STONE: 6,
  QUEST_STORY_EPISODE: 7,
  HONOR: 8,
  EXTERNAL_BILLED_STONE: 9,
};

export const ContentTypes = [
  { id: ContentType.CHARACTER_CARD, name: "キャラクター" },
  { id: ContentType.EQUIPMENT_CARD, name: "装備" },
  { id: ContentType.ITEM, name: "アイテム" },
  { id: ContentType.MONEY, name: "マネー" },
  { id: ContentType.STONE, name: "無償ジュエル" },
  { id: ContentType.BILLED_STONE, name: "有償ジュエル" },
  { id: ContentType.QUEST_STORY_EPISODE, name: "クエストストーリー" },
  { id: ContentType.HONOR, name: "称号" },
  { id: ContentType.EXTERNAL_BILLED_STONE, name: "外部決済有償ジュエル" },
];
