import { Datagrid, NumberInput, TextField } from "react-admin";
import { CommonList, NumField } from "../Common";

const userMoneyFilters = [<NumberInput key="money" source="Money" label="所持マネー" />];

export const UserMoneyList = (props: any) => (
  <CommonList {...props} addFilters={userMoneyFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="Money" label="所持マネー" />
    </Datagrid>
  </CommonList>
);
