import { TextInput } from "react-admin";
import { DateTimeWithSecInput } from "./Input";

export const CommonLogUserFilter = (addFilters: any) => {
  const filters = [
    <TextInput key="id" source="Id" label="ID" />,
    <TextInput key="user_id" source="UserId" label="ユーザーID" alwaysOn />,
    <TextInput key="LogUserActionId" source="LogUserActionId" label="アクションID" alwaysOn />,
  ];

  if (addFilters) {
    filters.push(...addFilters);
  }

  filters.push(
    <DateTimeWithSecInput key="createdAtFrom" source="CreatedAt_from" label="作成日時From" />,
    <DateTimeWithSecInput key="createdAtTo" source="CreatedAt_to" label="作成日時To" />
  );

  return filters;
};
