import { CommonList, YMDHMSDateField, NumField } from "components/Common";
import { Datagrid, TextField, NumberInput } from "react-admin";

const userSeasonPassPurchaseFilters = [
  <NumberInput key="seasonPassId" source="SeasonPassId" label="SeasonPassId" />,
  <NumberInput key="seasonPassGroupId" source="SeasonPassGroupId" label="SeasonPassGroupId" />,
];

export const UserSeasonPassPurchaseList = (props: any) => (
  <CommonList {...props} addFilters={userSeasonPassPurchaseFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="Id" label="ID" />
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="SeasonPassId" label="SeasonPassId" />
      <NumField source="SeasonPassGroupId" label="SeasonPassGroupId" />
      <YMDHMSDateField source="PurchasedAt" label="購入日時" />
      <YMDHMSDateField source="OpenAt" label="開始日時" />
      <YMDHMSDateField source="ExpiredAt" label="期限" />
      <YMDHMSDateField source="LastReceiveAt" label="最終受取日時" />
    </Datagrid>
  </CommonList>
);
