import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="questBattleDifficultyId" source="QuestBattleDifficultyId" label="クエストバトル難易度ID" />,
  <NumberInput key="count" source="Count" label="スキップ回数" />,
  <NumberInput key="partyNumber" source="PartyNumber" label="パーティ番号" />,
  <NumberInput key="totalPower" source="TotalPower" label="戦力" />,
]);

// TODO JSON field
export const LogUserBattleSkipList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="QuestBattleDifficultyId" label="クエストバトル難易度ID" />
    <NumField source="Count" label="スキップ回数" />
    <NumField source="PartyNumber" label="パーティ番号" />
    <NumField source="TotalPower" label="戦力" />
  </CommonLogUserList>
);
