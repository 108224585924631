import { Datagrid, FunctionField, List, ReferenceField, TextField, TextInput } from "react-admin";
import { CommonPagination, CommonActions, LogUserActionIdField, YMDHMSDateField } from "./index";
import { ActionTypes } from "../../common/ActionType";

export const CommonList = (props: any) => {
  let filters = [<TextInput key="userId" source="UserId" label="ユーザーID" alwaysOn sx={{ width: "350px" }} />];

  if (props.filters === null) {
    filters = [];
  } else if (props.filters) {
    filters = props.filters;
  } else if (props.addFilters) {
    filters.push(...props.addFilters);
  }

  let sort = { field: "UserId", order: "ASC" };
  if (props.sort) {
    sort = props.sort;
  }

  return (
    <List
      {...props}
      actions={<CommonActions showWildcardHelp={props?.showWildcardHelp} />}
      empty={false}
      perPage={100}
      pagination={<CommonPagination />}
      sort={sort}
      filters={filters}
    />
  );
};

interface CommonLogUserListProps {
  children: React.ReactNode;
  props: any;
}

export const CommonLogUserList: React.FC<CommonLogUserListProps> = ({ children, ...props }) => (
  <CommonList {...props} sort={{ field: "CreatedAt", order: "DESC" }}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="Id" label="ID" />
      <TextField source="UserId" label="ユーザーID" />
      <LogUserActionIdField source="LogUserActionId" label="アクションID" />
      <ReferenceField source="LogUserActionId" reference="LogUserAction" label="アクションタイプ">
        <FunctionField label="ActionType" render={(r: any) => `${r.ActionType}: ${ActionTypes[r.ActionType]}`} />
      </ReferenceField>
      {children}
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);

export const CommonMasterList = (props: any) => {
  return (
    <List
      {...props}
      actions={<CommonActions showWildcardHelp={props?.showWildcardHelp} />}
      empty={false}
      perPage={1000}
      pagination={<CommonPagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} />}
      filters={[]}
      sort={{}}
    />
  );
};
