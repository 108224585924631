import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField, ReferenceEquipmentCardField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="equipmentCardId" source="EquipmentCardId" label="メモリーカードID" />,
  <NumberInput key="equipmentCardNumber" source="EquipmentCardNumber" label="メモリーカード番号" />,
  <NumberInput key="rank" source="Rank" label="ランク" />,
  <NumberInput key="beforeRank" source="BeforeRank" label="ランクBefore" />,
  <NumberInput key="afterRank" source="AfterRank" label="ランクAfter" />,
]);

export const LogUserEquipmentCardRankList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="EquipmentCardId" label="メモリーカードID" />
    <ReferenceEquipmentCardField label="メモリーカード名" />
    <NumField source="EquipmentCardNumber" label="メモリーカード番号" />
    <NumField source="Rank" label="ランク" />
    <NumField source="BeforeRank" label="ランクBefore" />
    <NumField source="AfterRank" label="ランクAfter" />
  </CommonLogUserList>
);
