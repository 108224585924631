import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField, ReferenceShopMerchandiseField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="shopMerchandiseId" source="ShopMerchandiseId" label="ショップ商品ID" />,
  <NumberInput key="count" source="Count" label="数量" />,
  <NumberInput key="purchaseCount" source="PurchaseCount" label="購入回数" />,
  <NumberInput key="totalPurchaseCount" source="TotalPurchaseCount" label="総購入回数" />,
]);

export const LogUserShopMerchandiseList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="ShopMerchandiseId" label="ショップ商品ID" />
    <ReferenceShopMerchandiseField label="商品名" />
    <NumField source="Count" label="数量" />
    <NumField source="PurchaseCount" label="購入回数" />
    <NumField source="TotalPurchaseCount" label="総購入回数" />
  </CommonLogUserList>
);
