import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
  SelectInput,
  SelectField,
  ListProps,
  EditProps,
  CreateProps,
  required,
} from "react-admin";
import {
  CommonList,
  DateTimeWithSecInput,
  EditToolbar,
  EditTitle,
  YMDHMSDateField,
  NumField,
  ContentTypeWithUnifiedContentInput,
  NumInput,
  ContentReferenceField,
} from "../Common";
import { ContentTypes } from "common/ContentType";

const filters = [
  <TextInput key="presentId" source="PresentId" label="ID" />,
  <SelectInput key="contentType" source="ContentType" label="コンテンツタイプ" choices={ContentTypes} />,
  <NumberInput key="contentId" source="ContentId" label="コンテンツID" />,
  <NumberInput key="contentNum" source="ContentNum" label="個数" />,
  <DateTimeWithSecInput key="openAtFrom" source="OpenAt_from" label="付与開始日時From" />,
  <DateTimeWithSecInput key="openAtTo" source="OpenAt_to" label="付与開始日時To" />,
  <DateTimeWithSecInput key="expiredAtFrom" source="ExpiredAt_from" label="付与期限From" />,
  <DateTimeWithSecInput key="expiredAtTo" source="ExpiredAt_to" label="付与期限To" />,
  <TextInput key="customMessage" source="CustomMessage_like" label="カスタムメッセージ Like" />,
  <DateTimeWithSecInput key="userCreatedAtBeforeFrom" source="UserCreatedAtBefore_from" label="ユーザー作成日From" />,
  <DateTimeWithSecInput key="userCreatedAtBeforeTo" source="UserCreatedAtBefore_to" label="ユーザー作成日To" />,
  <DateTimeWithSecInput key="tutorialCompletedAtBeforeFrom" source="TutorialCompletedAtBefore_from" label="チュートリアル完了日From" />,
  <DateTimeWithSecInput key="tutorialCompletedAtBeforeTo" source="TutorialCompletedAtBefore_to" label="チュートリアル完了日To" />,
];

export const GlobalPresentList = (props: ListProps) => (
  <CommonList {...props} filters={filters} sort={{ field: "OpenAt", order: "DESC" }}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="PresentId" label="ID" />
      <SelectField source="ContentType" label="コンテンツタイプ" choices={ContentTypes} />
      <NumField source="ContentId" label="コンテンツID" />
      <ContentReferenceField label="コンテンツ詳細" />
      <NumField source="ContentNum" label="個数" />
      <YMDHMSDateField source="OpenAt" label="付与開始日時" />
      <YMDHMSDateField source="ExpiredAt" label="付与期限" />
      <TextField source="CustomMessage" label="カスタムメッセージ" />
      <YMDHMSDateField source="UserCreatedAtBefore" label="ユーザー作成日" />
      <YMDHMSDateField source="TutorialCompletedAtBefore" label="チュートリアル完了日" />
      <EditButton />
    </Datagrid>
  </CommonList>
);

export const GlobalPresentEdit = (props: EditProps) => (
  <Edit {...props} title={<EditTitle name="全体付与" />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <ContentTypeWithUnifiedContentInput />
      <NumInput source="ContentNum" label="個数" validate={required()} />
      <DateTimeWithSecInput source="OpenAt" label="付与開始日時" validate={required()} />
      <DateTimeWithSecInput source="ExpiredAt" label="付与期限" validate={required()} helperText="プレゼント付与の期限。受け取り期限ではありません。" />
      <TextInput source="CustomMessage" label="カスタムメッセージ" helperText="ユーザーに表示するメッセージ。省略可。" />
      <DateTimeWithSecInput source="UserCreatedAtBefore" label="ユーザー作成日" helperText="指定した日付以前に作成されたユーザーに付与。（未指定可）" />
      <DateTimeWithSecInput source="TutorialCompletedAtBefore" label="チュートリアル完了日" helperText="指定した日付以前にチュートリアルを完了したユーザーに付与。（未指定可）" />
    </SimpleForm>
  </Edit>
);

export const GlobalPresentCreate = (props: CreateProps) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <ContentTypeWithUnifiedContentInput />
      <NumInput source="ContentNum" label="個数" validate={required()} />
      <DateTimeWithSecInput source="OpenAt" label="付与開始日時" validate={required()} />
      <DateTimeWithSecInput source="ExpiredAt" label="付与期限" validate={required()} helperText="プレゼント付与の期限。受け取り期限ではありません。" />
      <TextInput source="CustomMessage" label="カスタムメッセージ" helperText="ユーザーに表示するメッセージ。省略可。" />
      <DateTimeWithSecInput source="UserCreatedAtBefore" label="ユーザー作成日" helperText="指定した日付以前に作成されたユーザーに付与。（未指定可）" />
      <DateTimeWithSecInput source="TutorialCompletedAtBefore" label="チュートリアル完了日" helperText="指定した日付以前にチュートリアルを完了したユーザーに付与。（未指定可）" />
    </SimpleForm>
  </Create>
);
