import { Datagrid, NumberInput, TextField } from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField, NumField, ReferenceShopMerchandiseField } from "../Common";

const userShopMerchandiseFilters = [
  <NumberInput key="shopMerchandiseId" source="ShopMerchandiseId" label="ShopMerchandiseId" />,
  <NumberInput key="purchaseCount" source="PurchaseCount" label="購入数" />,
  <NumberInput key="totalPurchaseCount" source="TotalPurchaseCount" label="総購入数" />,
  <DateTimeWithSecInput key="lastPurchasedAtFrom" source="LastPurchasedAt_from" label="最終購入日時From" />,
  <DateTimeWithSecInput key="lastPurchasedAtTo" source="LastPurchasedAt_to" label="最終購入日時To" />,
];

export const UserShopMerchandiseList = (props: any) => (
  <CommonList {...props} addFilters={userShopMerchandiseFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="ShopMerchandiseId" label="ShopMerchandiseId" />
      <ReferenceShopMerchandiseField label="商品名" />
      <NumField source="PurchaseCount" label="購入数" />
      <NumField source="TotalPurchaseCount" label="総購入数" />
      <YMDHMSDateField source="LastPurchasedAt" label="最終購入日時" />
    </Datagrid>
  </CommonList>
);
