import { NumberInput, TextInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField, YMDHMSDateField, DateTimeWithSecInput } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="towerStageId" source="TowerStageId" label="タワーステージID" />,
  <NumberInput key="enemyGroupId1" source="EnemyGroupId_1" label="敵グループID 1" />,
  <NumberInput key="enemyGroupId2" source="EnemyGroupId_2" label="敵グループID 2" />,
  <NumberInput key="enemyGroupId3" source="EnemyGroupId_3" label="敵グループID 3" />,
  <NumberInput key="partyNumber" source="PartyNumber" label="パーティ番号" />,
  <NumberInput key="totalPower" source="TotalPower" label="総戦力" />,
  <TextInput key="party" source="Party" label="パーティ" />,
  <DateTimeWithSecInput key="battleExpiredAtFrom" source="BattleExpiredAt_from" label="バトル有効期限From" />,
  <DateTimeWithSecInput key="battleExpiredAtTo" source="BattleExpiredAt_to" label="バトル有効期限From" />,
]);

// TODO JSON field
export const LogUserTowerBattleStartList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="TowerStageId" label="タワーステージID" />
    <NumField source="EnemyGroupId_1" label="敵グループID 1" />
    <NumField source="EnemyGroupId_2" label="敵グループID 2" />
    <NumField source="EnemyGroupId_3" label="敵グループID 3" />
    <NumField source="PartyNumber" label="パーティ番号" />
    <NumField source="TotalPower" label="総戦力" />
    <YMDHMSDateField source="BattleExpiredAt" label="バトル有効期限" />
  </CommonLogUserList>
);
