import { Datagrid, NumberInput, TextField } from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField, NumField } from "../Common";

const userGachaFilters = [
  <NumberInput key="gachaId" source="GachaId" label="GachaId" />,
  <NumberInput key="purchaseCount" source="PurchaseCount" label="現在の購入数" />,
  <NumberInput key="totalPurchaseCount" source="TotalPurchaseCount" label="総購入数" />,
  <DateTimeWithSecInput key="lastPurchasedAtFrom" source="LastPurchasedAt_from" label="最終購入日時From" />,
  <DateTimeWithSecInput key="lastPurchasedAtTo" source="LastPurchasedAt_to" label="最終購入日時To" />,
];

export const UserGachaList = (props: any) => (
  <CommonList {...props} addFilters={userGachaFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="UserId" />
      <NumField source="GachaId" label="GachaId" />
      <NumField source="PurchaseCount" label="現在の購入数" />
      <NumField source="TotalPurchaseCount" label="総購入数" />
      <YMDHMSDateField source="LastPurchasedAt" label="最終購入日時" />
    </Datagrid>
  </CommonList>
);
