import { Datagrid, NumberInput, TextField, ReferenceField, ListProps } from "react-admin";
import { CommonList, DateTimeWithSecInput, NumField, ReferenceMissionField, YMDHMSDateField } from "../Common";

const userMissionFilters = [
  <NumberInput key="missionId" source="MissionId" label="MissionId" />,
  <NumberInput key="progress" source="Progress" label="進捗" />,
  <DateTimeWithSecInput key="lastProgressedAtFrom" source="LastProgressedAt_from" label="進捗更新日時From" />,
  <DateTimeWithSecInput key="lastProgressedAtTo" source="LastProgressedAt_to" label="進捗更新日時To" />,
  <DateTimeWithSecInput key="rewardReceivedAtFrom" source="RewardReceivedAt_from" label="報酬受取日時From" />,
  <DateTimeWithSecInput key="rewardReceivedAtTo" source="RewardReceivedAt_to" label="報酬受取日時To" />,
];

export const UserMissionList = (props: ListProps) => (
  <CommonList {...props} addFilters={userMissionFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="MissionId" label="MissionId" />
      <ReferenceMissionField label="ミッション名" />
      <ReferenceField source="MissionId" reference="Mission" label="Value">
        <TextField source="value" />
      </ReferenceField>
      <NumField source="Progress" label="進捗" />
      <YMDHMSDateField source="LastProgressedAt" label="進捗更新日時" />
      <YMDHMSDateField source="RewardReceivedAt" label="報酬受取日時" />
    </Datagrid>
  </CommonList>
);
