import { Datagrid, TextField, TextInput } from "react-admin";
import { CommonList } from "../Common";

const userDeviceTokenFilters = [<TextInput key="deviceToken" source="DeviceToken" label="端末トークン" />];

export const UserDeviceTokenList = (props: any) => (
  <CommonList {...props} addFilters={userDeviceTokenFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <TextField source="DeviceToken" label="端末トークン" />
    </Datagrid>
  </CommonList>
);
