import { Datagrid, TextField, TextInput, NumberInput } from "react-admin";
import { CommonList, YMDHMSDateField, NumField } from "../Common";

const userBattleStatusFilters = [
  <NumberInput key="questBattleDifficultyId" source="QuestBattleDifficultyId" label="QuestBattleDifficultyId" />,
  <NumberInput key="enemyGroupId1" source="EnemyGroupId_1" label="EnemyGroupId_1" />,
  <NumberInput key="enemyGroupId2" source="EnemyGroupId_2" label="EnemyGroupId_2" />,
  <NumberInput key="enemyGroupId3" source="EnemyGroupId_3" label="EnemyGroupId_3" />,
  <NumberInput key="continueCount" source="ContinueCount" label="コンティニュー回数" />,
  <TextInput key="status" source="Status" label="ステータス" />,
  <NumberInput key="partyNumber" source="PartyNumber" label="パーティNO" />,
];

export const UserBattleStatusList = (props: any) => (
  <CommonList {...props} addFilters={userBattleStatusFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="QuestBattleDifficultyId" label="QuestBattleDifficultyId" />
      <NumField source="EnemyGroupId_1" label="EnemyGroupId_1" />
      <NumField source="EnemyGroupId_2" label="EnemyGroupId_2" />
      <NumField source="EnemyGroupId_3" label="EnemyGroupId_3" />
      <NumField source="ContinueCount" label="コンティニュー回数" />
      <TextField source="Status" label="ステータス" />
      <NumField source="PartyNumber" label="パーティNO" />
      <YMDHMSDateField source="BattleStartedAt" label="バトル開始日" />
      <YMDHMSDateField source="BattleExpiredAt" label="バトル有効期限" />
    </Datagrid>
  </CommonList>
);
