import * as React from "react";
import { DateField, NumberField, useRecordContext } from "react-admin";

export const YMDHMDateField = (props: any) => (
  <DateField
    {...props}
    options={{
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }}
  />
);

export const YMDHMSDateField = (props: any) => (
  <DateField
    {...props}
    options={{
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }}
  />
);

export const TextWithBreaksField = (props: any) => {
  const record = useRecordContext();
  const formattedText = record[props.source]
    ? record[props.source].split("\n").map((line: string, lineIndex: number) => (
        <React.Fragment key={lineIndex}>
          {line}
          {lineIndex !== record[props.source].length - 1 && <br />}
        </React.Fragment>
      ))
    : "";

  return <span style={{ whiteSpace: "pre-wrap" }}>{formattedText}</span>;
};

export const NumField = (props: any) => <NumberField {...props} options={{ style: "decimal", useGrouping: false }} />;

export const PathField = ({ source }: any) => {
  const record = useRecordContext();

  const handleClick = () => {
    window.open(record[source], "_blank", "noopener,noreferrer");
  };

  return (
    <div
      onClick={handleClick}
      style={{
        cursor: "pointer",
        textDecoration: "none",
      }}
    >
      {record[source]}
    </div>
  );
};
