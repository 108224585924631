import { NumberInput, TextField, TextInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <TextInput key="name" source="Name" label="名前" />,
  <TextInput key="comment" source="Comment" label="コメント" />,
  <NumberInput key="honorId" source="HonorId" label="称号ID" />,
  <NumberInput key="cardType" source="CardType" label="カードタイプ" />,
  <NumberInput key="cardId" source="CardId" label="カードID" />,
]);

export const LogUserProfileList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <TextField source="Name" label="名前" />
    <TextField source="Comment" label="コメント" />
    <NumField source="HonorId" label="称号ID" />
    <NumField source="CardType" label="カードタイプ" />
    <NumField source="CardId" label="カードID" />
  </CommonLogUserList>
);
