import { NumberInput, BooleanInput, BooleanField } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="count" source="Count" label="回数" />,
  <NumberInput key="gachaId" source="GachaId" label="ガチャID" />,
  <NumberInput key="gachaContentId" source="GachaContentId" label="ガチャコンテンツID" />,
  <NumberInput key="cardType" source="CardType" label="カードタイプ" />,
  <NumberInput key="cardId" source="CardId" label="カードID" />,
  <BooleanInput key="isConverted" source="IsConverted" label="変換済み" />,
]);

export const LogUserGachaDetailList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="Count" label="回数" />
    <NumField source="GachaId" label="ガチャID" />
    <NumField source="GachaContentId" label="ガチャコンテンツID" />
    <NumField source="CardType" label="カードタイプ" />
    <NumField source="CardId" label="カードID" />
    <BooleanField source="IsConverted" label="変換済み" />
  </CommonLogUserList>
);
