import { NumberInput } from "react-admin";
import { CommonLogUserFilter, CommonLogUserList, NumField } from "../Common";

const filters = CommonLogUserFilter([
  <NumberInput key="count" source="Count" label="獲得数" />,
  <NumberInput key="beforeCount" source="BeforeCount" label="獲得数Before" />,
  <NumberInput key="afterCount" source="AfterCount" label="獲得数After" />,
]);

export const LogUserStaminaList = (props: any) => (
  <CommonLogUserList filters={filters} {...props}>
    <NumField source="Count" label="獲得数" />
    <NumField source="BeforeCount" label="獲得数Before" />
    <NumField source="AfterCount" label="獲得数After" />
  </CommonLogUserList>
);
