import { Datagrid, NumberInput, TextField } from "react-admin";
import { CommonList, NumField, ReferenceItemField } from "../Common";

const userItemFilters = [<NumberInput key="itemId" source="ItemId" label="ItemId" />, <NumberInput key="num" source="Num" label="所持数" />];

export const UserItemList = (props: any) => (
  <CommonList {...props} addFilters={userItemFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="ItemId" label="ItemId" />
      <ReferenceItemField label="アイテム名" />
      <NumField source="Num" label="所持数" />
    </Datagrid>
  </CommonList>
);
