import { Datagrid, TextField, NumberInput, ListProps } from "react-admin";
import { CommonList, DateTimeWithSecInput, NumField, YMDHMSDateField } from "../Common";

const userQuestBattleMissionFilters = [
  <NumberInput key="questBattleMissionId" source="QuestBattleMissionId" label="QuestBattleMissionId" />,
  <DateTimeWithSecInput key="createdAtFrom" source="CreatedAt_from" label="作成日時From" />,
  <DateTimeWithSecInput key="createdAtTo" source="CreatedAt_to" label="作成日時To" />,
];

export const UserQuestBattleMissionList = (props: ListProps) => (
  <CommonList {...props} addFilters={userQuestBattleMissionFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="QuestBattleMissionId" label="QuestBattleMissionId" />
      <YMDHMSDateField source="CreatedAt" label="作成日時" />
    </Datagrid>
  </CommonList>
);
