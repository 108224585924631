import { Datagrid, TextField, NumberInput, ReferenceField, ListProps } from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField, NumField, ReferenceMissionAchievedCountField } from "../Common";

const userMissionAchievedCountFilters = [
  <NumberInput key="missionAchievedCountId" source="MissionAchievedCountId" label="MissionAchievedCountId" />,
  <NumberInput key="progress" source="Progress" label="進捗" />,
  <DateTimeWithSecInput key="lastProgressedAtFrom" source="LastProgressedAt_from" label="進捗更新日時From" />,
  <DateTimeWithSecInput key="lastProgressedAtTo" source="LastProgressedAt_to" label="進捗更新日時To" />,
  <DateTimeWithSecInput key="rewardReceivedAtFrom" source="RewardReceivedAt_from" label="報酬受取日時From" />,
  <DateTimeWithSecInput key="rewardReceivedAtTo" source="RewardReceivedAt_to" label="報酬受取日時To" />,
];

export const UserMissionAchievedCountList = (props: ListProps) => (
  <CommonList {...props} addFilters={userMissionAchievedCountFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="MissionAchievedCountId" label="MissionAchievedCountId" />
      <ReferenceMissionAchievedCountField label="ミッション名" />
      <ReferenceField source="MissionAchievedCountId" reference="MissionAchievedCount" label="Value">
        <TextField source="value" />
      </ReferenceField>
      <NumField source="Progress" label="進捗" />
      <YMDHMSDateField source="LastProgressedAt" label="進捗更新日" />
      <YMDHMSDateField source="RewardReceivedAt" label="報酬受取日時" />
    </Datagrid>
  </CommonList>
);
