import { Datagrid, NumberInput, TextField, ListProps } from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField, NumField } from "../Common";

const userBattlePassRewardFilters = [
  <NumberInput key="battlePassRewardId" source="BattlePassRewardId" label="BattlePassRewardId" />,
  <DateTimeWithSecInput key="receivedAtFrom" source="ReceivedAt_from" label="受取日時From" />,
  <DateTimeWithSecInput key="receivedAtTo" source="ReceivedAt_to" label="受取日時To" />,
];

export const UserBattlePassRewardList = (props: ListProps) => (
  <CommonList {...props} addFilters={userBattlePassRewardFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="BattlePassRewardId" label="BattlePassRewardId" />
      <YMDHMSDateField source="ReceivedAt" label="受取日時" />
    </Datagrid>
  </CommonList>
);
